import { useState } from "react";
import { CustomButton } from "../../components/CustomButton";
import { SizedBox } from "../../components/SizedBox";
import styles from './styles.module.css';
import logo from '../../assets/images/logo.png'
import { Link } from "react-router-dom";

const RegistrationType = () => {
    const [yes, setYes] = useState(0);

    return <div className={styles.body}>
        <div className={styles.header}>
        <div className={styles.logo_holder}>
                <img style={{height:100, width:150}} src={logo}/>
               
                {/* <span className={styles.logo_banner}>By Messenger</span> */}
            </div>
        </div>
        <div className={styles.container}>
            <p className={styles.title}>Before we get you started...</p>
            <p className={styles.warn_text}>Are you registered  on the Messenger Pilot app as a rider?</p>
            <SizedBox height={30} />
            <button onClick={() => setYes(1)} className={yes == 1 ? styles.selected_options_btn : styles.options_btn}>
                {yes == 1 ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="4.5" y="4.5" width="15" height="15" rx="7.5" fill="#FADBE1" stroke="#DE183D" />
                    <rect x="9" y="9" width="6" height="6" rx="3" fill="#DE183D" />
                </svg> :
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25ZM10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 14.1421 14.1421 17.5 10 17.5Z" fill="#595959" />
                    </svg>}
                <span className={styles.option_btn_text}>Yes, I am</span>
            </button>
            <SizedBox height={15} />
            <button onClick={() => setYes(2)} className={yes == 2 ? styles.selected_options_btn : styles.options_btn}>
                {yes == 2 ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="4.5" y="4.5" width="15" height="15" rx="7.5" fill="#FADBE1" stroke="#DE183D" />
                    <rect x="9" y="9" width="6" height="6" rx="3" fill="#DE183D" />
                </svg> :
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25ZM10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 14.1421 14.1421 17.5 10 17.5Z" fill="#595959" />
                    </svg>}
                <span className={styles.option_btn_text}>No, I am not</span>
            </button>
            <SizedBox height={20} />
            <CustomButton eventType='button' link={yes == 1 ? '/auth/rider-registeration' : yes == 2 ? '/auth/register' : null} title='Continue' />
            <SizedBox height={20} />
            <p className={styles.warn_text}>Already registered? <Link className={styles.red_warn} to='/auth'> Sign in</Link></p>
        </div>
    </div>
};

export default RegistrationType;