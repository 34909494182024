import { CustomButton } from '../../components/CustomButton';
import { useNavigate } from 'react-router-dom';
import { SizedBox } from '../../components/SizedBox';
import { TextInput } from '../../components/TextInput';
import { useContext, useState } from 'react';
import styles from './styles.module.css';
import AppContext from '../../context/app_context';
import axios from 'axios';
import { ErrorMessage } from '../../components/ErrorMessage';
import { SuccessMessage } from '../../components/SuccessMessage';
import { AccountContext } from './AccountLayout';
import { toast } from 'react-toastify';

const BVN = () => {
    const navigate = useNavigate();
    const [updating, setUpdating] = useState(false);
    const { user } = useContext(AppContext)
    const { profile, setProfile } = useContext(AccountContext);

    const [error, setError] = useState(false)
    const [success, setSuccess] = useState('');

    const [bvn, setBvn] = useState('');
    const [digits, setDigits] = useState('');
    const [dob, setDob] = useState('')

    const updateID = async (e) => {


        try {
            setUpdating(true)
            e.preventDefault();
            let birthDate = new Date(dob).toISOString().slice(0, 10);
            console.log({ birthDate })
            const response = await axios.patch(`${process.env.REACT_APP_API_URL}/m-flex/verify/bvn`, {
                "bvn": bvn,
                last4digits: digits,
                dob

            }, {
                headers: {
                    Authorization: `Bearer ${user?.access_token}`
                }
            });


            console.log({ res: response.data.data })

            setUpdating(false)
            setProfile({ ...profile, bvn })
            toast.success(response.data.message, {
                onClose: () => {
                  navigate('/account/documents/id')
                },
                theme: "light",
                autoClose: 2000,
              });
        } catch (error) {

            console.log({ error })
            setUpdating(false)
            if (error.response) {
                return setError(error.response.data.message);
            }
            return setError(error.msessage);
        }
    }
    return <form className={styles.profile_body} onSubmit={updateID}>
        {error && <ErrorMessage message={error} close={() => setError(false)} />}
        {success && <SuccessMessage message={success} close={() => setSuccess(false)} />}
        <div>
            <div className={styles.title_bar}>
                <button type='button' onClick={() => navigate('/account/documents')} className={styles.back_btn}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 18L9 12L15 6" stroke="#666D80" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </button>
                <p className={styles.title_bar_text}>BVN</p>
            </div>
            <div className={styles.text_holder}>
                <div className={styles.text_container}>
                    <TextInput value={bvn} handleInputChange={({ target }) => setBvn(target.value)} label='BVN' />
                    <span className={styles.bvn_format}>Dial *565*0# to check your BVN</span>
                </div>
                <div className={styles.text_container}>
                    <TextInput value={digits} handleInputChange={({ target }) => setDigits(target.value)} label='Last 4 digits of linked phone number' />
                </div>
            </div>
            <SizedBox height={20} />
            <div className={styles.birth_holder}>
                <TextInput value={dob} label='Date of Birth' handleInputChange={({ target }) => setDob(target.value)} type='date' />
            </div>
        </div>
        <div className={styles.footer}>
            <CustomButton loading={updating} title='Submit' />
        </div>
    </form>
};

export default BVN;