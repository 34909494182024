import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { CustomButton } from "../../components/CustomButton";
import { ErrorMessage } from "../../components/ErrorMessage";
import { SelectInput } from "../../components/SelectInput";
import { SizedBox } from "../../components/SizedBox";
import { SuccessMessage } from "../../components/SuccessMessage";
import { TextInput } from "../../components/TextInput";
import AppContext from "../../context/app_context";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ResidentialInformation = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const { user } = useContext(AppContext);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState("");

  const [states, setStates] = useState([]);
  const [lgas, setLgas] = useState([]);

  const [addressInfo, setAddressInfo] = useState({
    address: "",
    state: "",
    lga: "",
  });

  const handleGoBack = () => {
    navigate('/account');
  };

  const getData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/profile/kyc/residential-address`,
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );

      let data = {
        address: "",
        state: "",
        lga: "",
      };

      for (let i = 0; i < res.data.data.length; i++) {
        if (res.data.data[i].slug == "permanent-address")
          data.address = res.data.data[i].value;
        if (res.data.data[i].slug == "country-state") {
          data.state = res.data.data[i].value;
        }

        if (res.data.data[i].slug == "state-localty") {
          data.lga = res.data.data[i].value;
        }
      }
      setAddressInfo(data);
      const resp = await axios.get(
        `${process.env.REACT_APP_API_URL}/utils/states?country_id=1`,
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
      setStates(resp.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log({ error });
    }
  };

  const getLgas = async (state) => {
    try {
      console.log("calliing lgas all", { state });
      if (addressInfo.state) {
        for (let i = 0; i < states.length; i++) {
          if (states[i].name == state) {
            const res = await axios.get(
              `${process.env.REACT_APP_API_URL}/utils/localties?state_id=${states[i].id}`,
              {
                headers: {
                  Authorization: `Bearer ${user?.access_token}`,
                },
              }
            );
            setLgas(res.data.data);
          }
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const updateData = async (e) => {
    try {
      setUpdating(true);
      e.preventDefault();
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/profile/kyc/residential-address`,
        {
          data: [
            {
              value: addressInfo.address,
              slug: "permanent-address",
            },
            {
              value: addressInfo.state,
              slug: "country-state",
            },
            {
              value: addressInfo.lga,
              slug: "state-localty",
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );

      setUpdating(false);
      toast.success(response.data.message, {
        onClose: () => {
          navigate('/account/guarantors')
        },
        theme: "light",
        autoClose: 2000,
      });

    } catch (error) {
      setUpdating(false);

      if (error.response) {
        return setError(error.response.data.message);
      }
      return setError(error.msessage);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getLgas(addressInfo.state);
  }, [addressInfo.state, states]);

  if (loading)
    return (
      <div className={styles.loading_body}>
        <svg
          width="48"
          height="48"
          viewBox="-2 -2 48 48"
          xmlns="http://www.w3.org/2000/svg"
          stroke="#DE183D"
        >
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="5">
              <circle
                stroke-opacity=".5"
                stroke="#fff"
                cx="18"
                cy="18"
                r="18"
              />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      </div>
    );
  return (
    <form className={styles.profile_body} onSubmit={updateData}>
      {error && <ErrorMessage message={error} close={() => setError(false)} />}
      {success && (
        <SuccessMessage message={success} close={() => setSuccess(false)} />
      )}

      <div>
        <div className={styles.title_bar}>
          <div className={styles.title_bar_wrap}>
            <button
              type='button'
              className={styles.title_bar_button}
              onClick={handleGoBack}
              aria-label="Go Back"
            >
              <svg
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 14L2 8L8 2"
                  stroke="#666D80"
                  stroke-width="2.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <p className={styles.title_bar_text}>Residential Information</p>
          </div>
        </div>

        <div className={styles.text_holder}>
          <div className={styles.text_container}>
            <TextInput
              required
              value={addressInfo.address}
              handleInputChange={({ target }) =>
                setAddressInfo({ ...addressInfo, address: target.value })
              }
              label="Residential Information"
            />
          </div>
          <div className={styles.text_container}>
            <SelectInput
              required
              value={addressInfo.state}
              handleInputChange={({ target }) =>
                setAddressInfo({ ...addressInfo, state: target.value })
              }
              options={[
                "Select state",
                ...states.map((state) => state.name.toString()),
              ]}
              label="State"
            />
          </div>
        </div>
        <SizedBox height={5} />
        {addressInfo.state && (
          <div className={styles.birth_holder}>
            <SelectInput
              required
              value={addressInfo.lga}
              handleInputChange={({ target }) =>
                setAddressInfo({ ...addressInfo, lga: target.value })
              }
              options={[
                "Select LGA",
                ...lgas.map((lga) => lga.name.toString()),
              ]}
              label="LGA"
            />
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <CustomButton loading={updating} title="Submit" />
      </div>
    </form>
  );
};

export default ResidentialInformation;