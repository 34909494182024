import axios from "axios";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CustomButton } from "../../components/CustomButton";
import { ErrorMessage } from "../../components/ErrorMessage";
import { SizedBox } from "../../components/SizedBox";
import { PasswordInput, TextInput } from "../../components/TextInput";
import RegistrationContext from "../../context/auth_context";
import styles from './styles.module.css';
import logo from '../../assets/images/logo.png'

const Registeration = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
  
    const {
        data,
        updateData,
    } = useContext(RegistrationContext);


    const { first_name, last_name, phone, email, password, referal_code } = data;
    async function handleSubmit(e) {
        setLoading(true)
        e.preventDefault();
        try {
           const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/m-flex/register/otp`, {
                "phone_number": phone,
            });
            setLoading(false);
            console.log({response:response.data})
            navigate("/auth/verify-otp");
        } catch (error) {
            console.log({error})
            setLoading(false);
            if (error.response) {
                setError(error.response.data.message)
            } else {
                setError(error.message);
            }
        }
    }

    return <div className={styles.body}>
        {error&&<ErrorMessage message={error} close={()=>setError(false)} />}
        <div className={styles.header}>
        <div className={styles.logo_holder}>
                <img style={{height:100, width:150}} src={logo}/>
               
                {/* <span className={styles.logo_banner}>By Messenger</span> */}
            </div>
        </div>
        <form className={styles.container} onSubmit={handleSubmit}>
            <button onClick={() => navigate(-1)} className={styles.back_btn}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 11H7.83L12.71 6.11997C13.1 5.72997 13.1 5.08997 12.71 4.69997C12.32 4.30997 11.69 4.30997 11.3 4.69997L4.71 11.29C4.32 11.68 4.32 12.31 4.71 12.7L11.3 19.29C11.69 19.68 12.32 19.68 12.71 19.29C13.1 18.9 13.1 18.27 12.71 17.88L7.83 13H19C19.55 13 20 12.55 20 12C20 11.45 19.55 11 19 11Z" fill="#595959" />
                </svg>
            </button>
            <p className={styles.title}>Create your account</p>
            <SizedBox height={20} />
            <TextInput required label='First Name'
                name='firstname'
                value={first_name}
                handleInputChange={({ target }) => {
                    updateData('first_name', target.value)
                }} />
            <SizedBox height={10} />
            <TextInput required label='Last Name' name='lastname'
                value={last_name}
                handleInputChange={({ target }) => {
                    updateData('last_name', target.value)
                }} />
            <SizedBox height={10} />
            <TextInput required label='Email Address'
                name='email'
                value={email}
                handleInputChange={({ target }) => {
                    updateData('email', target.value)
                }} />
            <SizedBox height={10} />
            <TextInput required label='Phone Number' name='phone'
                value={phone}
                handleInputChange={({ target }) => {
                    updateData('phone', target.value)
                }} />
            <SizedBox height={10} />
            <PasswordInput required label='Password' name='password'
                value={password}
                handleInputChange={({ target }) => {
                    updateData('password', target.value)
                }} />
            <SizedBox height={20} />
            <CustomButton loading={loading}  link='/verify-otp' title='Register' />
            <SizedBox height={20} />
            <p className={styles.warn_text}>Already registered? <Link className={styles.red_warn} to='/auth'> Sign in</Link></p>
        </form>
    </div>
};

export default Registeration;