import axios from 'axios';
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import AppContext from '../../context/app_context';
import { AccountContext } from './AccountLayout';
import styles from './styles.module.css';

const Documents = () => {
    const [loading, setLoading] = useState(true)
    const { user } = useContext(AppContext)
    const [nin, setNin] = useState(false);
    const [address, setAddress] = useState(false);
    const { profile, } = useContext(AccountContext);
    const navigate = useNavigate();

    const handleGoBack = () => {
      navigate('/account');
    };
    const getData = async () => {
        try {
            setLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/profile/kyc/valid-identification`, {
                headers: {
                    Authorization: `Bearer ${user?.access_token}`
                }
            });
         
            for (let i = 0; i < res.data.data.length; i++) {
                if (res.data.data[i].slug == 'nin' && res.data.data[i].value)
                    setNin(true)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log({ error })

        }
    }


    useEffect(() => {
        getData();
    }, []);
    if (loading) return <div className={styles.loading_body}>
        <svg width="48" height="48" viewBox="-2 -2 48 48" xmlns="http://www.w3.org/2000/svg" stroke="#DE183D">
            <g fill="none" fill-rule="evenodd">
                <g transform="translate(1 1)" stroke-width="5">
                    <circle stroke-opacity=".5" stroke="#fff" cx="18" cy="18" r="18" />
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 18 18"
                            to="360 18 18"
                            dur="1s"
                            repeatCount="indefinite" />
                    </path>
                </g>
            </g>
        </svg>
    </div>
    return <div className={styles.profile_body}>
        <div>
        <div className={styles.title_bar}>
          <div className={styles.title_bar_wrap}>
            <button
            type='button' 
              className={styles.title_bar_button}
              onClick={handleGoBack}
              aria-label="Go Back"
            >
              <svg
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 14L2 8L8 2"
                  stroke="#666D80"
                  stroke-width="2.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <p className={styles.title_bar_text}>Documents</p>
          </div>
        </div>
            <NavLink to={profile.bvn ? '' : 'bvn'} className={styles.row}>
                <span className={styles.row_option}>BVN</span>
                {profile.bvn && <div className={styles.verified_btn}>
                    <span >Verified</span>
                </div>}
                {!profile.bvn && <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 15L12.5 10L7.5 5" stroke="#666D80" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>}
            </NavLink>
            <div className={styles.divider} />
            <NavLink to={nin ? '' : 'id'} className={styles.row}>
                <span className={styles.row_option}>Identity Verification</span>
                {nin && <div className={styles.verified_btn}>
                    <span >Verified</span>
                </div>}
                {!nin && <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 15L12.5 10L7.5 5" stroke="#666D80" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>}
            </NavLink>
            <div className={styles.divider} />
            <NavLink to={(address || profile.address_document_id) ? '' : 'address'} className={styles.row}>
                <span className={styles.row_option}>Address Verification</span>
                {address && <div className={styles.verified_btn}>
                    <span >Verified</span>
                </div>}
                {
                    !address && profile.address_document_id ? <div className={styles.pending_btn}>
                        <span >Pending</span>
                    </div> : <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.5 15L12.5 10L7.5 5" stroke="#666D80" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                }
            </NavLink>
            <div className={styles.divider} />
        </div>

    </div>
};

export default Documents;