import axios from "axios";
import { useState } from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CustomButton } from "../../components/CustomButton";
import { ErrorMessage } from "../../components/ErrorMessage";
import { SizedBox } from "../../components/SizedBox";
import {  TextInput } from "../../components/TextInput";
import AppContext from "../../context/app_context";
import RegistrationContext from "../../context/auth_context";
import styles from './styles.module.css';
import logo from '../../assets/images/logo.png'

const ForgotPasswordPage = () => {
    const {
        data,
        updateData,
    } = useContext(RegistrationContext);
    const { setUser } = useContext(AppContext)

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('')
    const { phone } = data;
    const navigate = useNavigate();
    async function handleSubmit(e) {
        setLoading(true)
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/m-flex/reset-password/otp`, {
                "phone_number": phone,
            });
            setLoading(false);
            navigate('/auth/password-otp')

        } catch (error) {
            setLoading(false);
            if (error.response) {
                setError(error.response.data.message)
            } else {
                setError(error.message);
            }
        }
    }

    return <div className={styles.body}>
        {error && <ErrorMessage message={error} close={() => setError(false)} />}

        <div className={styles.header}>
            <div className={styles.logo_holder}>
                <img style={{ height: 100, width: 150 }} src={logo} />

                {/* <span className={styles.logo_banner}>By Messenger</span> */}
            </div>
        </div>
        <form className={styles.container} onSubmit={handleSubmit}>
            <p className={styles.title}>Forgot password</p>
            <p>Enter your registered phone number and we’ll send you password reset instructions</p>
            <SizedBox height={10} />
            <TextInput required label='Phone Number' name='phone'
                value={phone}
                handleInputChange={({ target }) => {
                    updateData('phone', target.value)
                }} />
            <SizedBox height={10} />
            <SizedBox height={20} />
            <CustomButton loading={loading} title='Continue' />
            <SizedBox height={20} />

            <p className={styles.warn_text}><Link className={styles.red_warn} to='/auth'>Go back to Sign in</Link></p>
        </form>
    </div>
};

export default ForgotPasswordPage;