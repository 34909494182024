import { AuthLayout } from "../components/authLayout/AuthLayout";
import CreatePasswordPage from "../pages/authPages/CreatePasswordPage";
import ForgotPasswordPage from "../pages/authPages/ForgotPasswordPage";
import LoginPage from "../pages/authPages/LoginPage";
import OTPVerification from "../pages/authPages/OTPVerification";
import PasswordOTPVerification from "../pages/authPages/PasswordOTPVerification";
import PasswordResetSuccessful from "../pages/authPages/PasswordResetSuccessful";
import Registeration from "../pages/authPages/Registeration";
import RegistrationType from "../pages/authPages/RegistrationTypePage";
import RiderRegisteration from "../pages/authPages/RiderRegisteration";
import SuccessfulRegisteration from "../pages/authPages/SuccessfulRegistration";
import HomePage from "../pages/home/Home";

export const AuthRoutes = [
    {
        path: '/',
        element: <HomePage />
    },
    {
        path: '/auth',
        element: <AuthLayout />,
        // errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <LoginPage />,
                // errorElement: <ErrorPage />,
            },
            {
                path: '/auth/forgot-password',
                element: <ForgotPasswordPage />,
            },
            {
                path: '/auth/create-password',
                element: <CreatePasswordPage />,
            },
            {
                path: '/auth/password-otp',
                element: <PasswordOTPVerification />,
            },
            {
                path: '/auth/account-type',
                element: <RegistrationType />,
            },
            {
                path: '/auth/rider-registeration',
                element: <RiderRegisteration />,
            },
            {
                path: '/auth/registeration-successful',
                element: <SuccessfulRegisteration />,
            },
            {
                path: '/auth/password-reset-successful',
                element: <PasswordResetSuccessful />,
            },
            {
                path: '/auth/register',
                element: <Registeration />,
            },
            {
                path: '/auth/verify-otp',
                element: <OTPVerification />,
            },
        ]
    }];