import { DashboardLayout } from "../components/dashboardLayout/DashboardLayout";
import ReferralHistory from "../components/ReferralHistory";
import Account from "../pages/account/Account";
import AccountLayout from "../pages/account/AccountLayout";
import AddressVerification from "../pages/account/AddressVerification";
import BankInformation from "../pages/account/BankInformation";
import BVN from "../pages/account/BVN";
import ChangePassword from "../pages/account/ChangePassword";
import Documents from "../pages/account/Documents";
import Guarantor from "../pages/account/Guarantor";
import GuarantorList from "../pages/account/GuarantorList";
import IDVerification from "../pages/account/IDVerification";
import NextOfKin from "../pages/account/NextOfKin";
import Profile from "../pages/account/Profile";
import Referrals from "../pages/account/Referrals";
import ResidentialInformation from "../pages/account/ResidentialInformation";
import Dashboard from "../pages/dashboard/Dashboard";
import Notifications from "../pages/notifications/Notifications";
import ViewRequest from "../pages/request/ViewRequest";
import Requests from "../pages/requests/Requests";
import Transactions from "../pages/transactions/Transactions";

export const AppRoutes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: <Dashboard />,
        // errorElement: <ErrorPage />,
      },
      {
        path: "/requests",
        element: <Requests />,
      },
      {
        path: "/request/:id",
        element: <ViewRequest />,
      },
      {
        path: "/transactions",
        element: <Transactions />,
      },
      {
        path: "/account",
        element: <AccountLayout />,
        children: [
          {
            index: true,
            element: <Account />,
          },
          {
            path: "/account/profile",
            element: <Profile />,
          },
          {
            path: "/account/documents",
            element: <Documents />,
          },
          {
            path: "/account/documents/bvn",
            element: <BVN />,
          },
          {
            path: "/account/documents/id",
            element: <IDVerification />,
          },
          {
            path: "/account/documents/address",
            element: <AddressVerification />,
          },
          {
            path: "/account/bank-information",
            element: <BankInformation />,
          },
          {
            path: "/account/residential-information",
            element: <ResidentialInformation />,
          },
          {
            path: "/account/guarantors",
            element: <GuarantorList />,
          },
          {
            path: "/account/guarantors/update",
            element: <Guarantor />,
          },
          {
            path: "/account/nok",
            element: <NextOfKin />,
          },
          {
            path: "/account/security",
            element: <ChangePassword />,
          },
          {
            path: "/account/referrals",
            element: <Referrals />,
          },
          {
            path: "/account/referrals/referral_history",
            element: <ReferralHistory />,
          },
        ],
      },
      {
        path: "/notifications",
        element: <Notifications />,
      },
    ],
  },
];