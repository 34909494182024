import { useContext } from "react";
import { createBrowserRouter, RouterProvider, useNavigate } from "react-router-dom";
import AppContext from "./context/app_context";
import { AppRoutes } from "./navigation/app_navigation";
import { AuthRoutes } from "./navigation/auth_navigation";
import { ToastContainer } from "react-toastify";
const authRouter = createBrowserRouter([...AuthRoutes]);
const appRouter = createBrowserRouter([...AppRoutes]);

const App = () => {
    const { user } = useContext(AppContext)

    return <>
        <RouterProvider router={user ? appRouter : authRouter} />
        <ToastContainer />
    </>
    
};

export default App