import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../components/CustomButton";
import { PasswordInput } from "../../components/TextInput";
import styles from "./styles.module.css";

const ChangePassword = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.profile_body}>
      <div>
        <div className={styles.title_bar}>
          <div className={styles.title_bar_wrap}>
            <button
              className={styles.title_bar_button}
              onClick={handleGoBack}
              aria-label="Go Back"
            >
              <svg
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 14L2 8L8 2"
                  stroke="#666D80"
                  stroke-width="2.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <p className={styles.title_bar_text}>Change Password</p>
          </div>
        </div>

        <div className={styles.text_holder}>
          <div className={styles.text_container}>
            <PasswordInput label="Old Password" />
          </div>
        </div>
        <div className={styles.text_holder}>
          <div className={styles.text_container}>
            <PasswordInput label="New Password" />
            <p className={styles.pass_text}>
              Must be at least{" "}
              <span className={styles.pass_span}>8 characters</span>, also
              containing at least{" "}
              <span className={styles.pass_span}>one number</span>, and at least{" "}
              <span className={styles.pass_span}>one special character</span>.
            </p>
          </div>
        </div>
        <div className={styles.text_holder}>
          <div className={styles.text_container}>
            <PasswordInput label="Confirm New Password" />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <CustomButton title="Submit" />
      </div>
    </div>
  );
};

export default ChangePassword;