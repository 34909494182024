
import styles from './styles.module.css';

const Notification = ({ data }) => {
    return <div className={styles.body}>
        <div className={styles.row}>
            <span className={styles.title}>{data.title}</span>
            <span className={styles.time}>{data.time}</span>
        </div>
        <span className={styles.description}>{data.description}</span>
    </div>
};

export default Notification;