import axios from "axios";
import { useState } from "react";
import { useContext } from "react";
import { CustomButton } from "../../components/CustomButton";
import { ErrorMessage } from "../../components/ErrorMessage";
import { SizedBox } from "../../components/SizedBox";
import { PasswordInput, TextInput } from "../../components/TextInput";
import AppContext from "../../context/app_context";
import RegistrationContext from "../../context/auth_context";
import styles from './styles.module.css';
import logo from '../../assets/images/logo.png'
import { Link, useNavigate } from "react-router-dom";

const LoginPage = () => {
    const navigate = useNavigate()
    const {
        data,
        updateData,
    } = useContext(RegistrationContext);
    const { setUser } = useContext(AppContext)

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('')
    const { phone, password } = data;

    async function handleSubmit(e) {
        setLoading(true)
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/m-flex/login`, {
                "phone_number": phone,
                "password": password
            });
            setLoading(false);
            setUser({ ...response.data.data, access_token: response.headers['access-token'] })
            navigate('/')
        } catch (error) {
            setLoading(false);
            if (error.response) {
                setError(error.response.data.message)
            } else {
                setError(error.message);
            }
        }
    }

    return <div className={styles.body}>
        {error && <ErrorMessage message={error} close={() => setError(false)} />}

        <div className={styles.header}>
            <div className={styles.logo_holder}>
                <img style={{height:100, width:150}} src={logo}/>
               
                {/* <span className={styles.logo_banner}>By Messenger</span> */}
            </div>
        </div>
        <form className={styles.container} onSubmit={handleSubmit}>
            <p className={styles.title}>Welcome back</p>
            <SizedBox height={10} />
            <TextInput required label='Phone Number' name='phone'
                value={phone}
                handleInputChange={({ target }) => {
                    updateData('phone', target.value)
                }} />
            <SizedBox height={10} />
            <PasswordInput type="login" required label='Password' name='password'
                value={password}
                handleInputChange={({ target }) => {
                    updateData('password', target.value)
                }} />

            <SizedBox height={20} />
            <Link to='/auth/forgot-password'><p className={styles.forgot_password_text}>Forgot password?</p></Link>
            <SizedBox height={20} />
            <CustomButton loading={loading} title='Sign in' />
            <SizedBox height={20} />

            <p className={styles.warn_text}>Don't have an M-Flex account? <Link className={styles.red_warn} to='/auth/account-type'> Sign up</Link></p>
        </form>
    </div>
};

export default LoginPage;