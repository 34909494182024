import { Link } from 'react-router-dom';
import { SizedBox } from '../../../components/SizedBox';
import { money_format } from '../../../utils/money_format';
import titleCase from '../../../utils/title_case';
import styles from './styles.module.css';
import wallet from '../../../assets/images/wallet_with_cash.png';
import scooter from '../../../assets/images/Scooter.I13 2.png'
const RequestCard = ({ request }) => {
    const date = new Date(request.repayment_date)
    const today = new Date();
    
    let time_difference = today.getTime() - date.getTime();  
    let days_difference = time_difference / (1000 * 60 * 60 * 24);  
    if(request.repayment_date){
        console.log({days_difference:days_difference.toFixed(0), date, today})

    }

    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    return <div className={styles.body}>
        <div className={styles.header_row}>
            <div className={styles.header}>
                <img src={request.type === 'cash-advance' ?wallet:scooter} alt='icon'/>
                {/* {request.type === 'cash-advance' ? <svg width="48" height="52" viewBox="0 0 48 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 40.0077L16 49.2455L48 30.7702L47.9999 12.2375L31.9998 3L0 21.5326V40.0077Z" fill="#2DB357" />
                    <path d="M0 21.5324L16 30.7699L47.9999 12.2373L48 30.7699L16 49.2452L0 40.0075V21.5324Z" fill="#0E834C" />
                    <path d="M16 49.2452V30.7699L47.9999 12.2373L48 30.7699L16 49.2452Z" fill="#1A4541" />
                    <path d="M31.9999 5.11523L4 21.1887L16.1466 28.1192L44.1465 12.046L31.9999 5.11523ZM16.1444 26.5516L6.73906 21.1851L32.0021 6.68295L41.4074 12.0495L16.1444 26.5516Z" fill="white" />
                    <path d="M24.4616 19.2079C27.3599 19.2079 29.7095 17.8737 29.7095 16.228C29.7095 14.5822 27.3599 13.248 24.4616 13.248C21.5632 13.248 19.2136 14.5822 19.2136 16.228C19.2136 17.8737 21.5632 19.2079 24.4616 19.2079Z" fill="white" />
                    <path d="M32.583 13.1027C33.8356 13.1027 34.851 12.5262 34.851 11.815C34.851 11.1039 33.8356 10.5273 32.583 10.5273C31.3304 10.5273 30.3149 11.1039 30.3149 11.815C30.3149 12.5262 31.3304 13.1027 32.583 13.1027Z" fill="#5EE1EB" />
                    <path d="M16 22.5689C17.2525 22.5689 18.268 21.9923 18.268 21.281C18.268 20.5698 17.2525 19.9932 16 19.9932C14.7474 19.9932 13.7319 20.5698 13.7319 21.281C13.7319 21.9923 14.7474 22.5689 16 22.5689Z" fill="white" />
                    <path d="M0 23.9062V24.358L15.9988 33.8328L16 33.3815L0 23.9062Z" fill="#2DB357" />
                    <path d="M0 26.3867V26.8386L15.9988 36.3133L16 35.862L0 26.3867Z" fill="#2DB357" />
                    <path d="M0 28.8682V29.32L15.9988 38.7949L16 38.3436L0 28.8682Z" fill="#2DB357" />
                    <path d="M0 31.3486V31.8005L15.9988 41.2753L16 40.8242L0 31.3486Z" fill="#2DB357" />
                    <path d="M0 33.8301V34.2819L15.9988 43.7568L16 43.3054L0 33.8301Z" fill="#2DB357" />
                    <path d="M0 36.3115V36.7634L15.9988 46.2381L16 45.7868L0 36.3115Z" fill="#2DB357" />
                    <path d="M47.9999 14.8184L16.001 33.3831L16 33.8332L47.9999 15.2676V14.8184Z" fill="#0E834C" />
                    <path d="M47.9999 17.2988L16.001 35.8637L16 36.3137L47.9999 17.748V17.2988Z" fill="#0E834C" />
                    <path d="M47.9999 19.7803L16.001 38.3452L16 38.7953L47.9999 20.2295V19.7803Z" fill="#0E834C" />
                    <path d="M48 22.2607L16.001 40.8258L16 41.2758L48 22.71V22.2607Z" fill="#0E834C" />
                    <path d="M16.001 43.3072L16 43.7573L48 25.1917V24.7422L16.001 43.3072Z" fill="#0E834C" />
                    <path d="M16.001 45.7878L16 46.2377L48 27.6722V27.2227L16.001 45.7878Z" fill="#0E834C" />
                </svg> :
                    <svg width="48" height="52" viewBox="0 0 48 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M43.3627 11.0469L43.7446 11.4351L47.625 15.9728L46.6501 16.6477L40.4476 13.0665L43.3627 11.0469Z" fill="#23292E" />
                        <path d="M41.8781 12.5979L44.5858 14.2495L46.6501 16.6482L47.625 15.9733L43.7446 11.4355L41.8781 12.5979Z" fill="#2E373D" />
                        <path d="M6.31718 40.5301C6.77164 40.5301 7.18839 40.228 7.314 39.7684L13.8728 15.7739C14.0234 15.2227 13.6989 14.6544 13.1481 14.5035C12.5973 14.3533 12.0286 14.6774 11.8777 15.2286L5.31888 39.2231C5.16837 39.7739 5.49284 40.3426 6.04398 40.4931C6.13517 40.5184 6.22672 40.5301 6.31718 40.5301Z" fill="#4D4D4D" />
                        <path d="M35.4384 11.7003C36.9991 10.7994 38.4204 10.7734 39.4879 11.4589L42.2627 13.0366C43.6763 13.6848 44.5859 15.4473 44.5859 18.0189C44.5859 22.4387 41.8987 27.5737 38.5837 29.4875C37.0409 30.3781 35.6343 30.414 34.5708 29.7526L31.6323 28.0893C30.2916 27.3957 29.4362 25.6628 29.4362 23.1693C29.4362 18.7491 32.1234 13.6145 35.4384 11.7003Z" fill="#23292E" />
                        <path d="M32.5812 24.9499C32.5812 29.3701 35.2684 31.4015 38.5837 29.4877C41.8987 27.5738 44.5859 22.4388 44.5859 18.019C44.5859 13.5989 41.8987 11.5671 38.5837 13.481C35.2684 15.3951 32.5812 20.5298 32.5812 24.9499Z" fill="#2E373D" />
                        <path d="M34.8816 23.6211C34.8816 26.3472 36.5391 27.6003 38.5836 26.4197C40.6282 25.2394 42.2856 22.0724 42.2856 19.3463C42.2856 16.6202 40.6282 15.3674 38.5836 16.5477C36.5391 17.7284 34.8816 20.895 34.8816 23.6211Z" fill="#CECECE" />
                        <path d="M25.212 26.0475L36.7631 19.3784C37.4281 18.9946 38.2444 18.9829 38.92 19.3469C39.499 19.4568 39.9824 19.9571 39.9824 20.6254C39.9824 21.8822 39.3119 23.0438 38.2239 23.673L25.2117 31.195L25.212 26.0475Z" fill="#23292E" />
                        <path d="M33.354 16.5352V20.2449C33.354 21.0271 32.9365 21.7504 32.259 22.1415L28.1838 24.4944C27.1119 25.1609 26.4604 26.3335 26.4604 27.5955V29.5309C26.4604 30.7244 25.8236 31.8274 24.7902 32.4239L21.4833 34.3334C21.057 34.5795 20.5776 34.5483 20.2052 34.333L13.3593 30.3929C13.3289 30.3768 13.2988 30.3596 13.2695 30.3413H13.2692C12.9194 30.1205 12.673 29.7352 12.673 29.2628C12.673 28.0931 12.4774 26.9315 12.0944 25.8259L10.9496 22.5205L33.354 16.5352Z" fill="#4D4D4D" />
                        <path d="M28.1838 20.5146L33.354 17.5293V20.2451C33.354 21.0273 32.9365 21.7506 32.259 22.1417L28.1838 24.4946V20.5146Z" fill="#525E65" />
                        <path d="M23.066 28.1519L38.032 19.5005C38.8984 18.9999 39.9824 19.625 39.9824 20.6259C39.9824 21.8831 39.3119 23.0443 38.2239 23.6735L23.066 32.4358V28.1519Z" fill="#525E65" />
                        <path d="M29.8546 7.61663L30.8503 5.89178C31.3271 5.06597 32.0127 4.38043 32.8381 3.90399L35.7041 2.24945C36.3853 1.85614 37.1591 1.95428 37.7157 2.35968L44.3401 6.11297C44.9583 6.39715 45.4182 7.01348 45.4182 7.79022C45.4182 9.50701 44.5023 11.0934 43.0155 11.9518L31.6307 18.5246L23.0662 11.6644L29.8546 7.61663Z" fill="#627681" />
                        <path d="M30.0128 15.6076L36.8009 11.5595L37.797 9.83462C38.2734 9.00918 38.959 8.32363 39.7848 7.84683L42.6504 6.19228C43.8805 5.4822 45.4182 6.3699 45.4182 7.79006C45.4182 9.50686 44.5023 11.0933 43.0155 11.9517L31.6307 18.5248L30.0128 15.6076Z" fill="#2E373D" />
                        <path d="M36.8008 11.5602L29.8545 7.61719L23.066 11.6653L30.0127 15.6083L36.8008 11.5602Z" fill="#525E65" />
                        <path d="M15.6677 11.7672C18.0265 10.373 20.9536 10.358 23.3267 11.728C23.3911 11.7654 29.3812 15.2414 29.9664 15.5809C30.0518 15.626 30.1364 15.6721 30.2202 15.7208C31.0933 16.2247 31.6309 17.156 31.6309 18.1638V18.5249L17.8434 26.4852L10.9498 22.4928V20.0011C10.9498 16.6136 12.7454 13.48 15.6677 11.7672Z" fill="#E33400" />
                        <path d="M31.6309 18.5246L17.8434 26.4849V23.9936C17.8434 20.6061 19.6389 17.4725 22.5616 15.7597C24.9204 14.3652 27.8475 14.3502 30.2202 15.7201C31.0933 16.2244 31.6309 17.1557 31.6309 18.1635V18.5246Z" fill="#872E02" />
                        <path d="M28.1841 20.5146L17.8434 26.4846L18.9885 29.79C19.3712 30.8953 19.5668 32.0572 19.5668 33.2269C19.5668 34.2106 20.6317 34.8251 21.4832 34.3336L24.79 32.4242C25.8239 31.8273 26.4607 30.7246 26.4607 29.5308V27.5953C26.4607 26.3334 27.1122 25.1608 28.1841 24.4946V20.5146Z" fill="#82A5B9" />
                        <path d="M6.44641 31.4926C8.00684 30.5914 9.42847 30.5654 10.496 31.2509L13.2708 32.8285C14.6843 33.4767 15.594 35.2393 15.594 37.8108C15.594 42.2306 12.9067 47.3656 9.5918 49.2795C8.04895 50.1701 6.64233 50.206 5.57922 49.5446L2.64038 47.8816C1.29932 47.188 0.443848 45.4551 0.443848 42.9612C0.443848 38.5411 3.1311 33.4064 6.44641 31.4926Z" fill="#23292E" />
                        <path d="M5.75793 29.9383C7.60767 28.8704 9.28528 28.8829 10.5154 29.7651L14.1515 31.8645C15.6698 32.6581 16.6377 34.6272 16.6377 37.4566C16.6377 39.6055 16.0778 41.902 15.1443 43.9986L14.7308 43.7972C13.8483 43.3672 13.4044 42.3689 13.6663 41.423C13.8937 40.6031 14.0204 39.7688 14.0204 38.9676C14.0204 35.8661 12.1344 34.4405 9.80823 35.7834C9.33472 36.0569 8.87988 36.425 8.45581 36.8633C7.75745 37.5851 6.57532 37.5108 5.93335 36.7384L5.71399 36.4748L3.1886 35.0059C2.70337 34.9982 2.21924 34.7873 1.88342 34.383L1.66406 34.1193C2.80481 32.3197 4.22205 30.8249 5.75793 29.9383Z" fill="#E33400" />
                        <path d="M3.58923 44.7418C3.58923 49.1619 6.27649 51.1937 9.59143 49.2795C12.9064 47.3657 15.594 42.231 15.594 37.8109C15.594 33.3907 12.9064 31.359 9.59143 33.2732C6.27649 35.187 3.58923 40.3216 3.58923 44.7418Z" fill="#2E373D" />
                        <path d="M5.8894 43.4141C5.8894 46.1401 7.54688 47.3933 9.59143 46.2126C11.636 45.0323 13.2935 41.8653 13.2935 39.1393C13.2935 36.4132 11.636 35.1604 9.59143 36.3407C7.54688 37.5214 5.8894 40.688 5.8894 43.4141Z" fill="#CECECE" />
                        <path d="M7.99072 42.2007C7.99072 43.3792 8.7074 43.9208 9.59143 43.4107C10.4755 42.9002 11.1921 41.5309 11.1921 40.3521C11.1921 39.1736 10.4755 38.632 9.59143 39.1422C8.7074 39.6527 7.99072 41.0219 7.99072 42.2007Z" fill="#627681" />
                        <path d="M14.0204 38.9671C14.0204 35.8657 12.1344 34.44 9.80823 35.7829C9.33435 36.0565 8.87988 36.4245 8.45544 36.8629C7.75708 37.5847 6.57532 37.5103 5.93298 36.738L5.71399 36.4743C6.85474 34.6744 8.27197 33.1799 9.80823 32.2933C13.5798 30.1155 16.6377 32.427 16.6377 37.4561C16.6377 39.6051 16.0778 41.9016 15.1443 43.9981L14.7308 43.7967C13.8483 43.3668 13.4044 42.3689 13.6663 41.4226C13.8937 40.6026 14.0204 39.7684 14.0204 38.9671Z" fill="#872E02" />
                        <path d="M9.6663 42.5584C10.1208 42.5584 10.5375 42.2563 10.6631 41.7967L17.222 17.8022C17.3725 17.2514 17.048 16.6827 16.4969 16.5318C15.9461 16.3817 15.3774 16.7058 15.2268 17.2569L8.66801 41.2514C8.5175 41.8022 8.84196 42.3709 9.39274 42.5214C9.48429 42.5467 9.57585 42.5584 9.6663 42.5584Z" fill="#4D4D4D" />
                        <path d="M12.672 7.58065C13.0295 7.58065 13.377 7.39498 13.5685 7.0632C13.8542 6.56881 13.6846 5.93637 13.1902 5.65072L10.5319 4.1163C10.0375 3.83065 9.40506 4.00021 9.11942 4.49459C8.83377 4.98935 9.00333 5.62143 9.49808 5.90707L12.156 7.44186C12.319 7.53598 12.497 7.58065 12.672 7.58065Z" fill="#2E373D" />
                        <path d="M8.72632 7.23242H12.1636C12.4716 7.23242 12.7217 6.9823 12.7217 6.67432C12.7217 6.36597 12.4716 6.11621 12.1636 6.11621H8.72632C8.41797 6.11621 8.16821 6.36597 8.16821 6.67432C8.16821 6.9823 8.41797 7.23242 8.72632 7.23242Z" fill="#2E373D" />
                        <path d="M18.3453 19.1678C19.7724 19.1678 21.1831 18.6071 22.2352 17.5323L25.4476 14.251C25.8471 13.8427 25.8406 13.1883 25.4322 12.7887C25.0243 12.3892 24.3695 12.3962 23.9699 12.8041L20.7572 16.0854C19.6794 17.1866 17.9941 17.4176 16.6596 16.6471L13.3707 14.7483C12.6222 14.3158 12.2413 13.4578 12.4237 12.6126L13.6838 6.76383C13.8039 6.20572 13.4491 5.65567 12.8906 5.53519C12.3325 5.41471 11.7824 5.76993 11.662 6.3284L10.4018 12.1768C10.0298 13.9031 10.8076 15.6562 12.3369 16.5391L15.6258 18.4379C16.4758 18.929 17.414 19.1678 18.3453 19.1678Z" fill="#4D4D4D" />
                        <path d="M28.1269 16.5354C28.4843 16.5354 28.8318 16.35 29.0233 16.0183C29.309 15.5235 29.1394 14.8914 28.6451 14.6058L25.2261 12.6319C24.7317 12.3463 24.0993 12.5158 23.8136 13.0106C23.528 13.505 23.6975 14.1374 24.1923 14.423L27.6109 16.3969C27.7735 16.491 27.9515 16.5354 28.1269 16.5354Z" fill="#2E373D" />
                        <path d="M8.42789 14.8936L10.2022 13.8689C11.882 12.8992 13.9514 12.8992 15.6309 13.8689C18.7272 15.6567 18.7272 20.1256 15.6309 21.9134L12.4463 23.7884C11.8776 24.1642 11.1162 24.1554 10.2791 23.672C8.51834 22.6554 7.09122 19.9286 7.09122 17.5812C7.09122 16.1544 7.61893 15.1975 8.42789 14.8936Z" fill="#82A5B9" />
                        <path d="M7.09131 17.5807C7.09131 19.9281 8.51843 22.6549 10.2792 23.6715C12.0399 24.6878 13.467 23.6089 13.467 21.2615C13.467 18.9141 12.0399 16.1869 10.2792 15.1707C8.51843 14.1541 7.09131 15.2333 7.09131 17.5807Z" fill="#4D4D4D" />
                        <path d="M8.01892 18.1167C8.01892 19.7811 9.03076 21.7147 10.2792 22.435C11.5272 23.1558 12.5391 22.3907 12.5391 20.7267C12.5391 19.0622 11.5272 17.1287 10.2792 16.408C9.03076 15.6872 8.01892 16.4523 8.01892 18.1167Z" fill="white" />
                        <path d="M25.0205 28.8527C25.0205 30.2919 24.1456 31.9636 23.066 32.5866C21.9868 33.2099 21.1119 32.5485 21.1119 31.1093C21.1119 29.6701 21.9868 27.9983 23.066 27.375C24.1456 26.7521 25.0205 27.4135 25.0205 28.8527Z" fill="white" />
                        <path d="M25.2114 18.2964C25.2535 18.2964 25.2963 18.2917 25.3392 18.2814C25.6391 18.2115 25.8255 17.9112 25.7556 17.6109L24.8635 13.7983C24.7932 13.4983 24.4932 13.3119 24.1929 13.3819C23.8926 13.4522 23.7062 13.7525 23.7766 14.0528L24.6687 17.8654C24.7287 18.1225 24.958 18.2964 25.2114 18.2964Z" fill="#2E373D" />
                    </svg>} */}
                <div className={styles.type_holder}>
                    <span className={request.type === "cash-advance" ? styles.cash_type : styles.asset_type}>{request.reason}</span>
                    <span className={styles.request_amount}>{request.assetDescription}</span>
                </div>
            </div>
            <button className={styles.overdue}>{(request.status !== 'accepted'&&request.status !== 'partial-payment')?request.status:request.status==='overdue'?`-${days_difference.toFixed(0)} overdue`:request.loan_term}</button>
        </div>
        <SizedBox height={10} />
        <div className={styles.row}>
            <span className={styles.title}>REQUEST</span>
            <span className={styles.title}>REQUEST VALUE</span>
        </div>
        <div className={styles.row}>
            <span className={styles.label}>{request.request}</span>
            <span className={styles.label}>{money_format(request.amount)}</span>
        </div>
        <SizedBox height={10} />
        <div className={styles.row}>
            <span className={styles.title}>REPAYMENT DATE</span>
            <span className={styles.title}>STATUS</span>
        </div>
        <div className={styles.row}>
            <span className={styles.label}>  {request.repayment_date ? date.toLocaleDateString(undefined, options) : 'N/A'}</span>
            <span className={styles.label}>{titleCase(request.status)}</span>
        </div>
        <Link to={`/request/${request.id}`} >
            <button className={styles.btn}>
                View more
            </button>
        </Link>
    </div>

};

export default RequestCard;