import axios from "axios";
import { useEffect } from "react";
import { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { CustomButton } from "../../components/CustomButton";
import { ErrorMessage } from "../../components/ErrorMessage";
import { SuccessMessage } from "../../components/SuccessMessage";
import { TextInput } from "../../components/TextInput";
import AppContext from "../../context/app_context";
import styles from "./styles.module.css";

const GuarantorList = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const { user } = useContext(AppContext);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState("");
  const [guarantor, setGuarantor] = useState({
    first_name: "",
    last_name: "",
    email_address: "",
    phone: "",
  });

  const [guarantors, setGuarantors] = useState([]);
  const handleGoBack = () => {
    navigate('/account');
  };

  const getData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/profile/kyc/guarantor`,
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
      let data = {
        first_name: "",
        last_name: "",
        email_address: "",
        phone: "",
      };
      for (let i = 0; i < res.data.data.length; i++) {
        if (res.data.data[i].slug == "first-name")
          data.first_name = res.data.data[i].value;
        if (res.data.data[i].slug == "last-name")
          data.last_name = res.data.data[i].value;
        if (res.data.data[i].slug == "email-address")
          data.email_address = res.data.data[i].value;
        if (res.data.data[i].slug == "phone-number")
          data.phone = res.data.data[i].value;
      }
      if(data.first_name)
      guarantors[0] = data;
      const res1 = await axios.get(
        `${process.env.REACT_APP_API_URL}/profile/kyc/guarantor-2`,
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
      let data1 = {
        first_name: "",
        last_name: "",
        email_address: "",
        phone: "",
      };
      for (let i = 0; i < res1.data.data.length; i++) {
        if (res1.data.data[i].slug == "first-name")
          data1.first_name = res1.data.data[i].value;
        if (res1.data.data[i].slug == "last-name")
          data1.last_name = res1.data.data[i].value;
        if (res1.data.data[i].slug == "email-address")
          data1.email_address = res1.data.data[i].value;
        if (res1.data.data[i].slug == "phone-number")
          data1.phone = res1.data.data[i].value;
      }
      if(data1.first_name)
      guarantors[1] = data1;

      setGuarantors(guarantors);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const updateData = async (e) => {
    try {
      setUpdating(true);
      e.preventDefault();
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/profile/kyc/guarantor`,
        {
          data: [
            {
              value: guarantor.first_name,
              slug: "first-name",
            },
            {
              value: guarantor.last_name,
              slug: "last-name",
            },

            {
              value: guarantor.email_address,
              slug: "email-address",
            },
            {
              value: guarantor.phone,
              slug: "phone-number",
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
      setUpdating(false);
      setSuccess(response.data.message);
    } catch (error) {
      setUpdating(false);

      if (error.response) {
        return setError(error.response.data.message);
      }
      return setError(error.msessage);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  console.log({ guarantors })
  if (loading)
    return (
      <div className={styles.loading_body}>
        <svg
          width="48"
          height="48"
          viewBox="-2 -2 48 48"
          xmlns="http://www.w3.org/2000/svg"
          stroke="#DE183D"
        >
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="5">
              <circle
                stroke-opacity=".5"
                stroke="#fff"
                cx="18"
                cy="18"
                r="18"
              />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      </div>
    );
  return (
    <form className={styles.profile_body} onSubmit={updateData}>
      {error && <ErrorMessage message={error} close={() => setError(false)} />}
      {success && (
        <SuccessMessage message={success} close={() => setSuccess(false)} />
      )}

      <div>
        <div className={styles.title_bar}>
          <div className={styles.title_bar_wrap}>
            <button
            type='button' 
              className={styles.title_bar_button}
              onClick={handleGoBack}
              aria-label="Go Back"
            >
              <svg
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 14L2 8L8 2"
                  stroke="#666D80"
                  stroke-width="2.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <p className={styles.title_bar_text}>Guarantors</p>
          </div>
        </div>
        {guarantors.length != 0 && (
          <div>
            {guarantors.map((item, index) => (
              <>
                <NavLink
                  to={`update?index=${index + 1}`}
                  className={styles.row}
                >
                  <span className={styles.row_option}>
                    {" "}
                    {item.last_name} {item.first_name}
                  </span>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 15L12.5 10L7.5 5"
                      stroke="#666D80"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </NavLink>
                <div className={styles.divider} />
              </>
            ))}
          </div>
        )}

        {guarantors.length == 0 && (
          <div className={styles.empty_container}>
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_920_12300)">
                <path
                  d="M57.5844 11.7188H7.65994C6.9864 11.7192 6.34057 11.9869 5.8643 12.4632C5.38804 12.9394 5.12029 13.5853 5.11987 14.2588V60.065C5.12029 60.7385 5.38804 61.3844 5.8643 61.8606C6.34057 62.3369 6.9864 62.6046 7.65994 62.605H57.5844C58.258 62.6047 58.9039 62.337 59.3802 61.8607C59.8565 61.3844 60.1243 60.7386 60.1247 60.065V14.2588C60.1243 13.5852 59.8565 12.9394 59.3802 12.4631C58.9039 11.9868 58.258 11.7191 57.5844 11.7188Z"
                  fill="#C6C6C6"
                />
                <path
                  d="M54.2976 46.687H10.9468C9.54405 46.687 8.40674 45.5497 8.40674 44.147V3.67483C8.40674 2.27208 9.54405 1.13477 10.9468 1.13477H54.2976C55.7004 1.13477 56.8377 2.27208 56.8377 3.67483V44.147C56.8377 45.5497 55.7004 46.687 54.2976 46.687Z"
                  fill="#F9F9F9"
                />
                <path
                  d="M9.21094 44.9509V4.47903C9.21094 3.07627 10.3482 1.93896 11.751 1.93896H55.1018C55.5973 1.93896 56.0579 2.08296 56.4484 2.32821C55.9994 1.61214 55.2052 1.13477 54.2976 1.13477H10.9468C9.54405 1.13477 8.40674 2.27208 8.40674 3.67483V44.147C8.40674 45.0548 8.88412 45.8487 9.60018 46.298C9.35494 45.9072 9.21094 45.4466 9.21094 44.9512V44.9509Z"
                  fill="url(#paint0_linear_920_12300)"
                />
                <path
                  d="M48.9638 9.6875H16.281V11.5501H48.9638V9.6875ZM48.9638 16.7994H16.281V18.6619H48.9638V16.7994ZM48.9638 23.9128H16.281V25.7754H48.9638V23.9128ZM48.9638 31.0247H16.281V32.8875H48.9638V31.0247Z"
                  fill="white"
                />
                <path
                  d="M20.176 25.0117L28.3887 33.2247H20.176V25.0117Z"
                  fill="url(#paint1_linear_920_12300)"
                />
                <path
                  d="M60.1247 33.9024V15.0058L56.8379 11.7188V33.9021H60.1247V33.9024Z"
                  fill="#C6C6C6"
                />
                <path
                  d="M63.9745 35.6126L60.4333 60.6806C60.2562 61.9339 59.1837 62.8653 57.9181 62.8653H7.32614C6.06056 62.8653 4.98804 61.9339 4.81099 60.6806L0.0254175 26.8076C-0.190451 25.2784 0.996172 23.9121 2.54057 23.9121H18.1644C19.43 23.9121 20.5025 24.8435 20.6796 26.0965L21.3067 30.5324C21.4838 31.7857 22.5563 32.7171 23.8219 32.7171H61.4599C63.0038 32.7171 64.1907 34.0834 63.9748 35.6126H63.9745Z"
                  fill="url(#paint2_linear_920_12300)"
                />
                <path
                  d="M51.4275 51.6824H13.8173C13.6111 51.6823 13.4121 51.6071 13.2574 51.4708C13.1028 51.3345 13.0031 51.1465 12.9771 50.942L12.5066 47.2163C12.4916 47.0971 12.5022 46.9761 12.5376 46.8614C12.573 46.7466 12.6323 46.6406 12.7118 46.5505C12.7912 46.4604 12.8889 46.3883 12.9984 46.3388C13.1079 46.2894 13.2266 46.2637 13.3467 46.2637H51.8981C52.0182 46.2637 52.1369 46.2894 52.2464 46.3388C52.3558 46.3883 52.4535 46.4604 52.533 46.5505C52.6124 46.6406 52.6718 46.7466 52.7072 46.8614C52.7426 46.9761 52.7531 47.0971 52.7382 47.2163L52.2676 50.942C52.2417 51.1465 52.142 51.3345 51.9874 51.4708C51.8327 51.6071 51.6337 51.6823 51.4275 51.6824Z"
                  fill="#D5D5D5"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_920_12300"
                  x1="34.1117"
                  y1="25.3976"
                  x2="1.98838"
                  y2="-6.72596"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="white" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_920_12300"
                  x1="25.6021"
                  y1="34.5425"
                  x2="15.675"
                  y2="24.6156"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#C2CECE" stop-opacity="0" />
                  <stop
                    offset="0.179"
                    stop-color="#AFBCBC"
                    stop-opacity="0.179"
                  />
                  <stop offset="1" stop-color="#5B6A6A" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_920_12300"
                  x1="32"
                  y1="23.9121"
                  x2="32"
                  y2="62.8653"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#EEF0F4" />
                  <stop offset="0.927" stop-color="#E4E4E4" />
                </linearGradient>
                <clipPath id="clip0_920_12300">
                  <rect width="64" height="64" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p className={styles.empty_title}>No guarantors added </p>
            <p className={styles.empty_sub_title}>
              Click the '+' button to add new guarantor
            </p>
          </div>
        )}
      </div>
      {guarantors.length < 2 && (
        <div className={styles.footer}>
          <NavLink
            to={`update?index=${guarantors.length + 1}`}
            className={styles.row}
          >
            <button type="button" className={styles.add_button}>
              +
            </button>
          </NavLink>
        </div>
      )}
    </form>
  );
};

export default GuarantorList;