import axios from 'axios';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../../components/CustomButton';
import { ErrorMessage } from '../../components/ErrorMessage';
import { SelectInput } from '../../components/SelectInput';
import { SuccessMessage } from '../../components/SuccessMessage';
import { TextInput } from '../../components/TextInput';
import AppContext from '../../context/app_context';
import styles from './styles.module.css';
import { toast } from 'react-toastify';

const IDVerification = () => {
    const navigate = useNavigate()
    const [ninType, setNinType] = useState('Real NIN');
    const { user } = useContext(AppContext)
    const [nin, setNin] = useState('');
    const [updating, setUpdating] = useState(false);

    const [error, setError] = useState(false)
    const [success, setSuccess] = useState('')

    const updateID = async (e) => {

        try {
            setUpdating(true)
            e.preventDefault();
            const response = await axios.patch(`${process.env.REACT_APP_API_URL}/profile/kyc/valid-identification`, {
                data: [
                    {
                        "value": ninType,
                        "slug": "nin-type"
                    },
                    {
                        "value": nin,
                        "slug": "nin"
                    }
                ]
            }, {
                headers: {
                    Authorization: `Bearer ${user?.access_token}`
                }
            });

            setUpdating(false)
            toast.success(response.data.message, {
                onClose: () => {
                  navigate('/account/documents/address')
                },
                theme: "light",
                autoClose: 2000,
              });
            
        } catch (error) {

            console.log({ error })
            setUpdating(false)
            if (error.response) {
                return setError(error.response.data.message);
            }
            return setError(error.msessage);
        }
    }

    return <form className={styles.profile_body} onSubmit={updateID}>

        {error && <ErrorMessage message={error} close={() => setError(false)} />}
        {success && <SuccessMessage message={success} close={() => setSuccess(false)} />}
        <div>
            <div className={styles.title_bar}>
                <button type='button'  onClick={() =>  navigate('/account/documents')} className={styles.back_btn}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 18L9 12L15 6" stroke="#666D80" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </button>
                <p className={styles.title_bar_text}>ID Verification</p>
            </div>

            <div className={styles.text_holder}>
                <div className={styles.text_container}>
                    <SelectInput options={['Real NIN', 'Virtual NIN']} handleInputChange={({ target }) => setNinType(target.value)} label='Choose Verification Method' />
                </div>
                {ninType == 'Virtual NIN' && <div className={styles.text_container}>
                    <TextInput value={nin} handleInputChange={({ target }) => setNin(target.value)} label='Virtual NIN' />
                    <p className={styles.virtual_nin_warn}>What is <span className={styles.nin_red}>virtual NIN</span>?</p>
                </div>}
                {ninType == 'Real NIN' && <div className={styles.text_container}>
                    <TextInput value={nin} handleInputChange={({ target }) => setNin(target.value)} label='NIN' />
                </div>}
            </div>

        </div>
        <div className={styles.footer}>
            <CustomButton loading={updating} title='Submit' />
        </div>
    </form>
};

export default IDVerification;