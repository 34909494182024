import React from "react"
import { CustomButton } from "../CustomButton";
import styles from './styles.module.css';

export const Overlay = ({ children, close, save, title, cancelText='Cancel', okText = 'save', showSave = true, showCancel = true , right=false, loading=false}) => {
    const reset = () => close()
    const handleSave = (e) => {
        e.preventDefault();    
        save();
        // close();
    }
    if(right)
    return <div className={styles.left_body}>
    <form className={styles.left_container} onReset={reset} onSubmit={handleSave}>
       <div>
       <div className={styles.header}>
            <p className={styles.title}>{title}</p>
            <button type="reset" className={styles.close_btn}>
                <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.6001 6.8999L7.40015 20.0999M7.40015 6.8999L20.6001 20.0999" stroke="#666D80" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
        </div>
        {children}
       </div>
        <div className={styles.footer}>
            {showCancel &&<div className={styles.cancel_btn_holder}>
                <CustomButton eventType='reset' title={cancelText} type='secondary' />
            </div>}
            {showSave && <div className={styles.ok_btn_holder}>
                <CustomButton loading={loading} eventType="submit" title={okText} />
            </div>}
        </div>
    </form>
</div>
    return <div className={styles.body}>
        <form className={styles.container} onReset={reset} onSubmit={handleSave}>
            <div className={styles.header}>
                <p className={styles.title}>{title}</p>
                <button type="reset" className={styles.close_btn}>
                    <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.6001 6.8999L7.40015 20.0999M7.40015 6.8999L20.6001 20.0999" stroke="#666D80" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </button>
            </div>
            {children}
            <div className={styles.footer}>
                {showCancel &&<div className={styles.cancel_btn_holder}>
                    <CustomButton eventType='reset' title={cancelText} type='secondary' />
                </div>}
                {showSave && <div className={styles.ok_btn_holder}>
                    <CustomButton loading={loading} eventType="submit" title={okText} />
                </div>}
            </div>
        </form>
    </div>
}