import axios from "axios";
import { useEffect } from "react";
import { useContext, useState } from "react";
import { CustomButton } from "../../components/CustomButton";
import { ErrorMessage } from "../../components/ErrorMessage";
import { SuccessMessage } from "../../components/SuccessMessage";
import { TextInput } from "../../components/TextInput";
import AppContext from "../../context/app_context";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const NextOfKin = () => {
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState("");
  const { user } = useContext(AppContext);
  const [nok, setNok] = useState({
    first_name: "",
    last_name: "",
    email_address: "",
    phone: "",
    relationship: "",
  });
  const handleGoBack = () => {
    navigate('/account');
  };

  const getData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/profile/kyc/next-of-kin`,
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
      let data = {
        first_name: "",
        last_name: "",
        email_address: "",
        phone: "",
        relationship: "",
      };
      for (let i = 0; i < res.data.data.length; i++) {
        if (res.data.data[i].slug == "first-name")
          data.first_name = res.data.data[i].value;
        if (res.data.data[i].slug == "last-name")
          data.last_name = res.data.data[i].value;
        if (res.data.data[i].slug == "email-address")
          data.email_address = res.data.data[i].value;
        if (res.data.data[i].slug == "phone-number")
          data.phone = res.data.data[i].value;
        if (res.data.data[i].slug == "relationship")
          data.relationship = res.data.data[i].value;
      }
      setNok(data);
      setLoading(false);
      console.log({ xx: res.data });
    } catch (error) {
      setLoading(false);
      console.log({ error });
    }
  };

  const updateData = async (e) => {
    try {
      setUpdating(true);
      e.preventDefault();
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/profile/kyc/next-of-kin`,
        {
          data: [
            {
              value: nok.first_name,
              slug: "first-name",
            },
            {
              value: nok.last_name,
              slug: "last-name",
            },

            {
              value: nok.email_address,
              slug: "email-address",
            },
            {
              value: nok.phone,
              slug: "phone-number",
            },
            {
              value: nok.relationship,
              slug: "relationship",
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );

      setUpdating(false);
      toast.success(response.data.message, {
        onClose: () => {
          navigate('/account')
        },
        theme: "light",
        autoClose: 2000,
      });
    } catch (error) {
      setUpdating(false);

      if (error.response) {
        return setError(error.response.data.message);
      }
      return setError(error.msessage);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading)
    return (
      <div className={styles.loading_body}>
        <svg
          width="48"
          height="48"
          viewBox="-2 -2 48 48"
          xmlns="http://www.w3.org/2000/svg"
          stroke="#DE183D"
        >
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="5">
              <circle
                stroke-opacity=".5"
                stroke="#fff"
                cx="18"
                cy="18"
                r="18"
              />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      </div>
    );
  return (
    <form className={styles.profile_body} onSubmit={updateData}>
      {error && <ErrorMessage message={error} close={() => setError(false)} />}
      {success && (
        <SuccessMessage message={success} close={() => setSuccess(false)} />
      )}

      <div>
        <div className={styles.title_bar}>
          <div className={styles.title_bar_wrap}>
            <button
            type='button' 
              className={styles.title_bar_button}
              onClick={handleGoBack}
              aria-label="Go Back"
            >
              <svg
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 14L2 8L8 2"
                  stroke="#666D80"
                  stroke-width="2.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <p className={styles.title_bar_text}>Next of Kin</p>
          </div>
        </div>

        <div className={styles.text_holder}>
          <div className={styles.text_container}>
            <TextInput
              value={nok.first_name}
              required
              label="First Name"
              handleInputChange={({ target }) =>
                setNok({ ...nok, first_name: target.value })
              }
            />
          </div>
          <div className={styles.text_container}>
            <TextInput
              value={nok.last_name}
              required
              label="Last Name"
              handleInputChange={({ target }) =>
                setNok({ ...nok, last_name: target.value })
              }
            />
          </div>
        </div>
        <div className={styles.text_holder}>
          <div className={styles.text_container}>
            <TextInput
              value={nok.phone}
              required
              label="Phone Number"
              handleInputChange={({ target }) =>
                setNok({ ...nok, phone: target.value })
              }
            />
          </div>
          <div className={styles.text_container}>
            <TextInput
              value={nok.email_address}
              required
              label="Email Address"
              handleInputChange={({ target }) =>
                setNok({ ...nok, email_address: target.value })
              }
            />
          </div>
        </div>
        <div className={styles.text_holder}>
          <div className={styles.text_container}>
            <TextInput
              value={nok.relationship}
              required
              label="Relationship"
              handleInputChange={({ target }) =>
                setNok({ ...nok, relationship: target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <CustomButton loading={updating} title="Submit" />
      </div>
    </form>
  );
};

export default NextOfKin;