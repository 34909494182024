import axios from "axios";
import { useMediaQuery } from "react-responsive";
import { createContext, useContext, useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import AppContext from "../../context/app_context";
import styles from "./styles.module.css";
export const AccountContext = createContext({});

const AccountLayout = () => {
  const { user } = useContext(AppContext);
  // const isDesktop = useMediaQuery({
  //   query: "(min-width: 992px)",
  // });

  const [profile, setProfile] = useState({ profile_verification_status: {} });
  const [fetching, setFetching] = useState(true);
  const getProfile = async () => {
    try {
      setFetching(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/m-flex/profile`,
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
      setProfile({ ...response.data.data });
      setFetching(false);
    } catch (error) {
      setFetching(false);
      console.log({ error });
    }
  };

  console.log({ profile: profile });

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <AccountContext.Provider value={{ profile, setProfile, fetching }}>
      <div className={styles.body}>
        <div className={styles.account_tier_holder}>
          <p className={styles.account_title_header}>My Account</p>
          <div className={styles.tier_holder}>
            <p className={styles.tier_title}>Tier</p>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.16675 12.4998C4.16675 9.27818 6.68515 6.6665 9.79175 6.6665H10.2084C13.315 6.6665 15.8334 9.27818 15.8334 12.4998C15.8334 15.7215 13.315 18.3332 10.2084 18.3332H9.79175C6.68515 18.3332 4.16675 15.7215 4.16675 12.4998Z"
                stroke="#0D0D12"
                stroke-width="1.5"
              />
              <path
                d="M10.4167 14.9995V10.7901C10.4167 10.3113 10.4167 10.072 10.2247 10.0124C9.80263 9.88141 9.16675 10.8325 9.16675 10.8325M10.4167 14.9995H9.16675M10.4167 14.9995H11.6667"
                stroke="#0D0D12"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.3001 1.6665L9.16675 6.58137M15.0001 1.6665L12.6489 7.08317"
                stroke="#0D0D12"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M8.69995 1.6665L10 4.66154M5 1.6665L7.3512 7.08317"
                stroke="#0D0D12"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
        {/* <div className={styles.menu_holder}>
          <NavLink
            to="/account"
            exact={true}
            activeClassName={styles.menu_text_active}
            className={({ isActive, isPending }) =>
              isActive ? styles.menu_text_active : styles.menu_text
            }
            end
          >
            {profile?.profile_verification_status.profile ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
                  stroke="#048848"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.5 4L10 11.5075L7.75 9.2575"
                  stroke="#048848"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5"
                  stroke="#DE183D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
            <span>Profile</span>
          </NavLink>
          <NavLink
            to="documents"
            className={({ isActive, isPending }) =>
              isActive ? styles.menu_text_active : styles.menu_text
            }
          >
            {profile?.profile_verification_status?.documents ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
                  stroke="#048848"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.5 4L10 11.5075L7.75 9.2575"
                  stroke="#048848"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5"
                  stroke="#DE183D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
            <span>Documents</span>
          </NavLink>
          <NavLink
            to="bank-information"
            className={({ isActive, isPending }) =>
              isActive ? styles.menu_text_active : styles.menu_text
            }
          >
            {profile?.profile_verification_status?.bank_info ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
                  stroke="#048848"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.5 4L10 11.5075L7.75 9.2575"
                  stroke="#048848"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5"
                  stroke="#DE183D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
            <span>Bank Information</span>
          </NavLink>
          <NavLink
            to="residential-information"
            className={({ isActive, isPending }) =>
              isActive ? styles.menu_text_active : styles.menu_text
            }
          >
            {profile?.profile_verification_status?.residential_info ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
                  stroke="#048848"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.5 4L10 11.5075L7.75 9.2575"
                  stroke="#048848"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5"
                  stroke="#DE183D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
            <span>Residential Information</span>
          </NavLink>
          <NavLink
            to="guarantors"
            className={({ isActive, isPending }) =>
              isActive ? styles.menu_text_active : styles.menu_text
            }
          >
            {profile?.profile_verification_status?.grantor ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
                  stroke="#048848"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.5 4L10 11.5075L7.75 9.2575"
                  stroke="#048848"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5"
                  stroke="#DE183D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
            <span>Guarantor</span>
          </NavLink>
          <NavLink
            to="nok"
            className={({ isActive, isPending }) =>
              isActive ? styles.menu_text_active : styles.menu_text
            }
          >
            {profile?.profile_verification_status?.next_of_kin ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
                  stroke="#048848"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.5 4L10 11.5075L7.75 9.2575"
                  stroke="#048848"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5"
                  stroke="#DE183D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
            <span>Next of Kin</span>
          </NavLink>
          <NavLink
            to="security"
            className={({ isActive, isPending }) =>
              isActive ? styles.menu_text_active : styles.menu_text
            }
          >
            <span>Security</span>
          </NavLink>
          <NavLink
            to="referrals"
            className={({ isActive, isPending }) =>
              isActive ? styles.menu_text_active : styles.menu_text
            }
          >
            <span>Referrals</span>
          </NavLink>
        </div> */}

        <div className={styles.child_container}>
          <Outlet />
        </div>
      </div>
    </AccountContext.Provider>
  );
};

export default AccountLayout;