import { Outlet } from "react-router-dom";
import styles from './styles.module.css';
import dashboard from '../../assets/images/DashboardImage.png'
import { RegistrationProvider } from "../../context/auth_context";

export const AuthLayout = () => {
    return <RegistrationProvider> <div className={styles.auth_container}>
        <Outlet />
        <div className={styles.background} >
            <div className={styles.image_holder}>
                <img className={styles.image} src={dashboard} />
            </div>
            <div className={styles.text_holder}>
                <span className={styles.auth_title}>Get the <span className={styles.red_text}>financial backing</span> to power your business</span>
                <p className={styles.auth_sub_title}>Looking to get started in the delivery space? Messenger has you covered. </p>
                <div className={styles.chat_contianer}>
                    <div className={styles.first_chat_holder}>
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_711_115213)">
                                <path d="M19.66 24.2H4.66C2.17453 24.2 0.16 22.1854 0.16 19.7V4.69995C0.16 2.21448 2.17453 0.199951 4.66 0.199951H19.66C22.1455 0.199951 24.16 2.21448 24.16 4.69995V19.7C24.16 22.1854 22.1455 24.2 19.66 24.2Z" fill="#FDF2F4" />
                                <path d="M11.6598 18.2C12.5651 18.2 13.3226 17.5549 13.4965 16.7H9.82235C9.99703 17.5549 10.7545 18.2 11.6598 18.2Z" fill="#F4AEBC" />
                                <path d="M15.1661 12.2C15.1637 12.2 15.1622 12.2 15.16 12.2C13.2303 12.2 11.6598 10.6302 11.6598 8.69971C11.6598 8.16943 11.7821 7.66772 11.9943 7.21619C11.8841 7.20575 11.7729 7.19897 11.6598 7.19897C9.72711 7.19897 8.15954 8.76562 8.15954 10.6992V12.0934C8.15954 13.0827 7.72595 14.0165 6.96551 14.66C6.71209 14.8768 6.59949 15.2217 6.69031 15.5577C6.79761 15.95 7.18377 16.1997 7.59027 16.1997H15.7278C16.1537 16.1997 16.5558 15.9238 16.6421 15.5067C16.7087 15.1842 16.5941 14.861 16.3434 14.651C15.616 14.0405 15.1961 13.145 15.1661 12.2Z" fill="#E64C68" />
                                <path d="M17.6598 8.69971C17.6598 10.0803 16.5407 11.1995 15.1601 11.1995C13.7795 11.1995 12.6603 10.0803 12.6603 8.69971C12.6603 7.31909 13.7795 6.19995 15.1601 6.19995C16.5407 6.19995 17.6598 7.31909 17.6598 8.69971Z" fill="#F4AEBC" />
                            </g>
                            <defs>
                                <clipPath id="clip0_711_115213">
                                    <rect width="24" height="24" fill="white" transform="translate(0.16 0.199951)" />
                                </clipPath>
                            </defs>
                        </svg>
                        <div className={styles.arr_holder} >
                            <span className={styles.text}>Your repayment is due tomorrow</span>
                            <div className={styles.side_arrow} />
                        </div>

                    </div>
                    <div className={styles.second_chat_holder}>
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_711_115213)">
                                <path d="M19.66 24.2H4.66C2.17453 24.2 0.16 22.1854 0.16 19.7V4.69995C0.16 2.21448 2.17453 0.199951 4.66 0.199951H19.66C22.1455 0.199951 24.16 2.21448 24.16 4.69995V19.7C24.16 22.1854 22.1455 24.2 19.66 24.2Z" fill="#FDF2F4" />
                                <path d="M11.6598 18.2C12.5651 18.2 13.3226 17.5549 13.4965 16.7H9.82235C9.99703 17.5549 10.7545 18.2 11.6598 18.2Z" fill="#F4AEBC" />
                                <path d="M15.1661 12.2C15.1637 12.2 15.1622 12.2 15.16 12.2C13.2303 12.2 11.6598 10.6302 11.6598 8.69971C11.6598 8.16943 11.7821 7.66772 11.9943 7.21619C11.8841 7.20575 11.7729 7.19897 11.6598 7.19897C9.72711 7.19897 8.15954 8.76562 8.15954 10.6992V12.0934C8.15954 13.0827 7.72595 14.0165 6.96551 14.66C6.71209 14.8768 6.59949 15.2217 6.69031 15.5577C6.79761 15.95 7.18377 16.1997 7.59027 16.1997H15.7278C16.1537 16.1997 16.5558 15.9238 16.6421 15.5067C16.7087 15.1842 16.5941 14.861 16.3434 14.651C15.616 14.0405 15.1961 13.145 15.1661 12.2Z" fill="#E64C68" />
                                <path d="M17.6598 8.69971C17.6598 10.0803 16.5407 11.1995 15.1601 11.1995C13.7795 11.1995 12.6603 10.0803 12.6603 8.69971C12.6603 7.31909 13.7795 6.19995 15.1601 6.19995C16.5407 6.19995 17.6598 7.31909 17.6598 8.69971Z" fill="#F4AEBC" />
                            </g>
                            <defs>
                                <clipPath id="clip0_711_115213">
                                    <rect width="24" height="24" fill="white" transform="translate(0.16 0.199951)" />
                                </clipPath>
                            </defs>
                        </svg>
                        <div className={styles.arr_holder} >
                            <span className={styles.text}>We don approve your loan request!</span>
                            <div className={styles.side_arrow} />
                        </div>
                    </div>
                    <div className={styles.third_chat_holder}>
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_711_115213)">
                                <path d="M19.66 24.2H4.66C2.17453 24.2 0.16 22.1854 0.16 19.7V4.69995C0.16 2.21448 2.17453 0.199951 4.66 0.199951H19.66C22.1455 0.199951 24.16 2.21448 24.16 4.69995V19.7C24.16 22.1854 22.1455 24.2 19.66 24.2Z" fill="#FDF2F4" />
                                <path d="M11.6598 18.2C12.5651 18.2 13.3226 17.5549 13.4965 16.7H9.82235C9.99703 17.5549 10.7545 18.2 11.6598 18.2Z" fill="#F4AEBC" />
                                <path d="M15.1661 12.2C15.1637 12.2 15.1622 12.2 15.16 12.2C13.2303 12.2 11.6598 10.6302 11.6598 8.69971C11.6598 8.16943 11.7821 7.66772 11.9943 7.21619C11.8841 7.20575 11.7729 7.19897 11.6598 7.19897C9.72711 7.19897 8.15954 8.76562 8.15954 10.6992V12.0934C8.15954 13.0827 7.72595 14.0165 6.96551 14.66C6.71209 14.8768 6.59949 15.2217 6.69031 15.5577C6.79761 15.95 7.18377 16.1997 7.59027 16.1997H15.7278C16.1537 16.1997 16.5558 15.9238 16.6421 15.5067C16.7087 15.1842 16.5941 14.861 16.3434 14.651C15.616 14.0405 15.1961 13.145 15.1661 12.2Z" fill="#E64C68" />
                                <path d="M17.6598 8.69971C17.6598 10.0803 16.5407 11.1995 15.1601 11.1995C13.7795 11.1995 12.6603 10.0803 12.6603 8.69971C12.6603 7.31909 13.7795 6.19995 15.1601 6.19995C16.5407 6.19995 17.6598 7.31909 17.6598 8.69971Z" fill="#F4AEBC" />
                            </g>
                            <defs>
                                <clipPath id="clip0_711_115213">
                                    <rect width="24" height="24" fill="white" transform="translate(0.16 0.199951)" />
                                </clipPath>
                            </defs>
                        </svg>
                        <div className={styles.arr_holder} >
                            <span className={styles.text}>Your bike don dey come!</span>
                            <div className={styles.side_arrow} />
                        </div>
                    </div>
                </div>
                <span>
                    {/* <svg width="143" height="32" viewBox="0 0 143 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 31.36V0.639999H4.34029L14.3009 22.4L24.2616 0.639999H28.6019V31.36H24.1016V11.6693L15.261 31.36H13.3409L4.5203 11.6693V31.36H0Z" fill="#DE183D" />
                        <path d="M33.821 18.0053V13.9733H45.8218V18.0053H33.821Z" fill="#DE183D" />
                        <path d="M51.019 31.36V0.639999H68.3601V5.78133H55.8393V13.44H65.96V18.56H55.8393V31.36H51.019Z" fill="#DE183D" />
                        <path d="M72.3579 31.36V0H77.1782V31.36H72.3579Z" fill="#DE183D" />
                        <path d="M109.892 32C107.706 32 88.8832 31.4951 87.2298 30.4853C85.5764 29.4756 84.2829 28.0747 83.3495 26.2827C82.4295 24.4907 81.9695 22.4284 81.9695 20.096C81.9695 17.5787 82.4228 15.3956 83.3295 13.5467C84.2363 11.6836 85.4963 10.24 87.1098 9.216C88.7232 8.192 107.492 7.68 109.612 7.68C111.853 7.68 113.753 8.24178 115.313 9.36533C116.886 10.4747 118.053 12.0462 118.813 14.08C119.573 16.1138 119.86 18.5102 119.673 21.2693H114.893V19.392C114.879 16.8889 114.466 15.0613 113.653 13.9093C112.839 12.7573 111.559 12.1813 109.812 12.1813C107.839 12.1813 89.4699 12.8356 88.5099 14.144C87.5498 15.4382 87.0698 17.3369 87.0698 19.84C87.0698 22.1724 87.5498 23.9787 88.5099 25.2587C89.4699 26.5387 107.772 27.1787 109.612 27.1787C110.799 27.1787 111.819 26.9013 112.673 26.3467C113.539 25.7778 114.206 24.96 114.673 23.8933L119.433 25.4293C118.606 27.5058 117.326 29.12 115.593 30.272C113.873 31.424 111.973 32 109.892 32ZM85.5497 21.2693V17.3867H117.313V21.2693H85.5497Z" fill="#DE183D" />
                        <path d="M120.954 31.36L128.855 19.712L121.114 8.32H126.795L131.655 15.68L136.435 8.32H142.116L134.375 19.712L142.316 31.36H136.635L131.655 23.744L126.635 31.36H120.954Z" fill="#DE183D" />
                    </svg> <span className={styles.auth_title}> is everything </span> */}

                </span>
                {/* <span className={styles.auth_title}>you need to keep you going!</span> */}
                {/* <span className={styles.auth_sub_title}>Get access to fuel credits, asset financing and so muc more.</span> */}
            </div>

        </div>
    </div>
    </RegistrationProvider>
};