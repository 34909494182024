import React from 'react';
import styles from './styles.module.css';


export const SelectInput = ({
  label,
  placeholder,
  size = 'small',
  type = 'text',
  required = false,
  important = false,
  name,
  autoFocus = false,
  value,
  readOnly,
  hint,
  handleInputChange,
  options=[],
  ...props
}) => {
  return (
    <div className={styles.input_container}>
      {label && (
        <label className={styles.input_label} htmlFor={name}>
          {label}
          {important && <span className={styles.required}> *</span>}
        </label>
      )}
      <select
        placeholder={placeholder}
        onChange={handleInputChange}
        value={value}
        autoFocus={autoFocus}
        required={required}
        type={type}
        id={name}
        name={name}
        className={styles.input_field}
        readOnly={readOnly}
        {...props}
      >
        {options.map((option, key) => {
          // Determine option type (string or object)
          const optionValue = typeof option === 'string' ? option : option.value;
          const optionLabel = typeof option === 'string' ? option : option.label;

          return (
            <option key={key} value={optionValue}>
              {optionLabel}
            </option>
          );
        })}
      </select>
      <span>{hint}</span>
    </div>
  );
};
