/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from '../../components/Loader';
import { SizedBox } from '../../components/SizedBox';
import AppContext from '../../context/app_context';
import { money_format } from '../../utils/money_format';
import titleCase from '../../utils/title_case';
import styles from './styles.module.css';

const ViewRequest = () => {
    let { id } = useParams();
    const { user } = useContext(AppContext)
    const [request, setRequest] = useState({})
    const [fetching, setFetching] = useState(true)
   
    const getRequests =async ()=>{
        try {
         setFetching(true)
         const response = await axios.get(`${process.env.REACT_APP_API_URL}/m-flex/loans/${id}`,{
             headers: {
                 Authorization: `Bearer ${user?.access_token}`
             }
         });
         setRequest(response.data.data);
         setFetching(false)
        } catch (error) {
         setFetching(false)
        }
     }

     useEffect(()=>{
        getRequests()
     },[])
    const date = new Date(request.repayment_date)
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    const navigate = useNavigate();

    if(fetching)
    return <Loader/>
    return <div className={styles.body}>
        <div className={styles.title_bar}>
            <button onClick={() => navigate(-1)} className={styles.back_btn}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 18L9 12L15 6" stroke="#666D80" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
            <p className={styles.title_bar_text}>Back to Requests</p>
        </div>
        <div className={styles.type_holder}>
            <span className={request.type === "cash-advance" ? styles.cash_type : styles.asset_type}>{request.type === "cash-advance" ? 'Cash Advance' : 'Asset Financing'}</span>
            <span className={styles.request_amount}>{request.reason}</span>
        </div>

        <div className={styles.card}>
            <p className={styles.card_title}>Request Details</p>
            <SizedBox height={10} />
            <div className={styles.row}>
                <span className={styles.title}>REQUEST</span>
                <span className={styles.title}>REQUEST VALUE</span>
            </div>
            <div className={styles.row}>
                <span className={styles.label}>{request.request}</span>
                <span className={styles.label}>{money_format(request.amount)}</span>
            </div>

            {request.type === "cash-advance" ?<>
                <SizedBox height={10} />
                <div className={styles.row}>
                    <span className={styles.title}>INTEREST RATE</span>
                    <span className={styles.title}>LOAN TERM</span>
                </div>
                <div className={styles.row}>
                    <span className={styles.label}>  1% weekly</span>
                    <span className={styles.label}>{request.loan_term}</span>
                </div>
            </>:
            <>
                <SizedBox height={10} />
                <div className={styles.row}>
                    <span className={styles.title}>INTEREST RATE</span>
                    <span className={styles.title}>TOTAL LOAN VALUE</span>
                </div>
                <div className={styles.row}>
                    <span className={styles.label}>  {request.interest}%</span>
                    <span className={styles.label}>{money_format(request.repayment_amount)}</span>
                </div>
            </>}
           {
           request.type !== "cash-advance"&& <>
             <SizedBox height={10} />
            <div className={styles.row}>
                <span className={styles.title}>LOAN TERM</span>
                <span className={styles.title}>INSTALMENT</span>
            </div>
            <div className={styles.row}>
                <span className={styles.label}>  {request.loan_term}</span>
                <span className={styles.label}>{money_format(request.repayment_amount/request.loan_term)} @ Once a month (28th of every month)</span>
            </div></>
           }

            <SizedBox height={10} />
            <div className={styles.row}>
                <span className={styles.title}>REPAYMENT DATE</span>
                <span className={styles.title}>STATUS</span>
            </div>
            <div className={styles.row}>
                <span className={styles.label}>  {request.repayment_date && date.toLocaleDateString(undefined, options)}</span>
                <span className={styles.label}>{titleCase(request.status)}</span>
            </div>
        </div>
    </div>
};

export default ViewRequest;