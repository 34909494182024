import axios from "axios";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { Loader } from "../../components/Loader";
import AppContext from "../../context/app_context";
import styles from './styles.module.css';
import TransactionCard from "./TransactionCard";
const Transactions = () => {
    const { user } = useContext(AppContext)

    const [transactions, setTransactions] = useState([

    ]);

    const [fetching, setFetching] = useState(true);

    const gettransactions = async () => {
        try {
            setFetching(true)
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/m-flex/transactions?page=1&limit=2`, {
                headers: {
                    Authorization: `Bearer ${user?.access_token}`
                }
            });
            setTransactions(response.data.data.items);
            setFetching(false)
        } catch (error) {
            setFetching(false)

        }
    }
    useEffect(() => {
        gettransactions();
    }, [])
    if (fetching) return <Loader />
    return <div >
        <div className={styles.body}>


            <div className={styles.white_card}>
                <div className={styles.card_nav}>
                    <span className={styles.trans_card_title}>Transactions</span>
                </div>
{/* 
                <div className={styles.filter_holder}>

                    <div className={styles.filter_container}>
                        <span className={styles.filter_text}>All descriptions</span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 9L12 15L18 9" stroke="#666D80" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div className={styles.filter_container}>
                        <span className={styles.filter_text}>All statuses</span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 9L12 15L18 9" stroke="#666D80" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div> */}
                <div className={styles.transaction_card_holder}>
                    {transactions.map((transaction, index) => <>
                        <TransactionCard transaction={transaction} />
                        {index !== (transactions.length - 1) && <div className={styles.transaction_row_dovider} />}
                    </>)}

                    {transactions.length == 0 && <div className={styles.empty_body}>
                        <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_861_23438)">
                                <path d="M57.5844 12.2192H7.65994C6.9864 12.2197 6.34057 12.4874 5.8643 12.9637C5.38804 13.4399 5.12029 14.0858 5.11987 14.7593V60.5655C5.12029 61.239 5.38804 61.8848 5.8643 62.3611C6.34057 62.8374 6.9864 63.1051 7.65994 63.1055H57.5844C58.258 63.1052 58.9039 62.8375 59.3802 62.3612C59.8565 61.8849 60.1243 61.2391 60.1247 60.5655V14.7593C60.1243 14.0857 59.8565 13.4398 59.3802 12.9636C58.9039 12.4873 58.258 12.2196 57.5844 12.2192Z" fill="#C6C6C6" />
                                <path d="M54.2976 47.188H10.9468C9.54405 47.188 8.40674 46.0507 8.40674 44.6479V4.17581C8.40674 2.77305 9.54405 1.63574 10.9468 1.63574H54.2976C55.7004 1.63574 56.8377 2.77305 56.8377 4.17581V44.6479C56.8377 46.0507 55.7004 47.188 54.2976 47.188Z" fill="#F9F9F9" />
                                <path d="M9.21094 45.4519V4.98C9.21094 3.57725 10.3482 2.43994 11.751 2.43994H55.1018C55.5973 2.43994 56.0579 2.58394 56.4484 2.82918C55.9994 2.11312 55.2052 1.63574 54.2976 1.63574H10.9468C9.54405 1.63574 8.40674 2.77305 8.40674 4.17581V44.6479C8.40674 45.5557 8.88412 46.3497 9.60018 46.799C9.35494 46.4082 9.21094 45.9476 9.21094 45.4521V45.4519Z" fill="url(#paint0_linear_861_23438)" />
                                <path d="M48.9638 10.187H16.281V12.0496H48.9638V10.187ZM48.9638 17.2989H16.281V19.1614H48.9638V17.2989ZM48.9638 24.4123H16.281V26.2749H48.9638V24.4123ZM48.9638 31.5242H16.281V33.387H48.9638V31.5242Z" fill="white" />
                                <path d="M20.176 25.5122L28.3887 33.7252H20.176V25.5122Z" fill="url(#paint1_linear_861_23438)" />
                                <path d="M60.1247 34.4028V15.5063L56.8379 12.2192V34.4026H60.1247V34.4028Z" fill="#C6C6C6" />
                                <path d="M63.9745 36.1116L60.4333 61.1797C60.2562 62.4329 59.1837 63.3643 57.9181 63.3643H7.32614C6.06056 63.3643 4.98804 62.4329 4.81099 61.1797L0.0254175 27.3066C-0.190451 25.7774 0.996172 24.4111 2.54057 24.4111H18.1644C19.43 24.4111 20.5025 25.3425 20.6796 26.5955L21.3067 31.0315C21.4838 32.2847 22.5563 33.2161 23.8219 33.2161H61.4599C63.0038 33.2161 64.1907 34.5824 63.9748 36.1116H63.9745Z" fill="url(#paint2_linear_861_23438)" />
                                <path d="M51.4275 52.1829H13.8173C13.6111 52.1828 13.4121 52.1076 13.2574 51.9713C13.1028 51.835 13.0031 51.647 12.9771 51.4425L12.5066 47.7168C12.4916 47.5976 12.5022 47.4766 12.5376 47.3618C12.573 47.2471 12.6323 47.1411 12.7118 47.051C12.7912 46.9609 12.8889 46.8887 12.9984 46.8393C13.1079 46.7898 13.2266 46.7642 13.3467 46.7642H51.8981C52.0182 46.7642 52.1369 46.7898 52.2464 46.8393C52.3558 46.8887 52.4535 46.9609 52.533 47.051C52.6124 47.1411 52.6718 47.2471 52.7072 47.3618C52.7426 47.4766 52.7531 47.5976 52.7382 47.7168L52.2676 51.4425C52.2417 51.647 52.142 51.835 51.9874 51.9713C51.8327 52.1076 51.6337 52.1828 51.4275 52.1829Z" fill="#D5D5D5" />
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_861_23438" x1="34.1117" y1="25.8986" x2="1.98838" y2="-6.22498" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="white" />
                                </linearGradient>
                                <linearGradient id="paint1_linear_861_23438" x1="25.6021" y1="35.043" x2="15.675" y2="25.1161" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#C2CECE" stop-opacity="0" />
                                    <stop offset="0.179" stop-color="#AFBCBC" stop-opacity="0.179" />
                                    <stop offset="1" stop-color="#5B6A6A" />
                                </linearGradient>
                                <linearGradient id="paint2_linear_861_23438" x1="32" y1="24.4111" x2="32" y2="63.3643" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#EEF0F4" />
                                    <stop offset="0.927" stop-color="#E4E4E4" />
                                </linearGradient>
                                <clipPath id="clip0_861_23438">
                                    <rect width="64" height="64" fill="white" transform="translate(0 0.5)" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span className={styles.content_card_title}>Nothing to show you yet</span>
                        <p className={styles.content_card_text}>Transactions that you make will show up here</p>
                    </div>}
                </div>

            </div>

        </div>
    </div>
};

export default Transactions;