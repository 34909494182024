import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AppContext from "../../context/app_context";

const ReferralHistory = () => {
  const navigate = useNavigate();
  const { user } = useContext(AppContext);

  const [referrals, setReferrals] = useState([]);

  useEffect(() => {
    const axiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${user?.access_token}`, // Add the access token to the Authorization header
      },
    });

    axiosInstance
      .get(`${process.env.REACT_APP_API_URL}/referrals`)
      .then((response) => {
        // Upon successful response, set the referrals state with the data
        setReferrals(response.data.data);
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error fetching referrals:", error);
      });

    // axios
    //   .get(`${process.env.REACT_APP_API_URL}/referrals`)
    //   .then((response) => {
    //     setReferrals(response.data);
    //     console.log(referrals);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching referrals:", error);
    //   });
  }, []);
  console.log(referrals, "refer");
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.profile_body}>
      <div className={styles.title_bar}>
        <div className={styles.title_bar_wrap}>
          <button
            className={styles.title_bar_button}
            onClick={handleGoBack}
            aria-label="Go Back"
          >
            <svg
              width="10"
              height="16"
              viewBox="0 0 10 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 14L2 8L8 2"
                stroke="#666D80"
                stroke-width="2.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <p className={styles.title_bar_text}>Referral History</p>
        </div>
      </div>

      <div className={styles.ref_first_div}>
        {referrals?.length === 0 ? (
          <div className={styles.nothing_to_display}>Nothing to display</div>
        ) : (
          referrals?.map((referral, index) => (
            <div className={styles.wrap} key={index}>
              <div className="">
                <p className={styles.refname_p}>
                  <span>{referral.first_name}</span>{" "}
                  <span>{referral.last_name}</span>
                </p>
                <div className={styles.refHis_flex}>
                  <p className={styles.refHis_p}>
                    <span className={styles.refHis_IB}>
                      {referral.profile_completed ? (
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.375"
                            y="0.375"
                            width="11.25"
                            height="11.25"
                            rx="5.625"
                            fill="#33AD71"
                          />
                          <rect
                            x="0.375"
                            y="0.375"
                            width="11.25"
                            height="11.25"
                            rx="5.625"
                            stroke="#33AD71"
                            stroke-width="0.75"
                          />
                          <g clip-path="url(#clip0_2195_33475)">
                            <path
                              d="M8.5 4.125L5.0625 7.5625L3.5 6"
                              stroke="white"
                              stroke-width="1.25"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2195_33475">
                              <rect
                                width="7.5"
                                height="7.5"
                                fill="white"
                                transform="translate(2.25 2.25)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      ) : (
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.375"
                            y="0.375"
                            width="11.25"
                            height="11.25"
                            rx="5.625"
                            fill="white"
                          />
                          <rect
                            x="0.375"
                            y="0.375"
                            width="11.25"
                            height="11.25"
                            rx="5.625"
                            stroke="#D0D5DD"
                            stroke-width="0.75"
                          />
                        </svg>
                      )}
                    </span>{" "}
                    Profile Completed
                  </p>
                  <p className={styles.refHis_p2}>
                    <span className={styles.refHis_IB}>
                      {referral.loan_requested ? (
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.375"
                            y="0.375"
                            width="11.25"
                            height="11.25"
                            rx="5.625"
                            fill="#33AD71"
                          />
                          <rect
                            x="0.375"
                            y="0.375"
                            width="11.25"
                            height="11.25"
                            rx="5.625"
                            stroke="#33AD71"
                            stroke-width="0.75"
                          />
                          <g clip-path="url(#clip0_2195_33475)">
                            <path
                              d="M8.5 4.125L5.0625 7.5625L3.5 6"
                              stroke="white"
                              stroke-width="1.25"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2195_33475">
                              <rect
                                width="7.5"
                                height="7.5"
                                fill="white"
                                transform="translate(2.25 2.25)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      ) : (
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.375"
                            y="0.375"
                            width="11.25"
                            height="11.25"
                            rx="5.625"
                            fill="white"
                          />
                          <rect
                            x="0.375"
                            y="0.375"
                            width="11.25"
                            height="11.25"
                            rx="5.625"
                            stroke="#D0D5DD"
                            stroke-width="0.75"
                          />
                        </svg>
                      )}
                    </span>{" "}
                    Loan request
                  </p>
                </div>
              </div>
              <div>
                <p className={styles.refHis_p2}>
                  Status:{" "}
                  {referral.profile_completed && referral.loan_requested ? (
                    <span className={styles.refHis_span1}>Completed</span>
                  ) : (
                    <span className={styles.refHis_span2}>In Progress</span>
                  )}
                </p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ReferralHistory;
