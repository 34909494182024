import axios from "axios";
import { useEffect } from "react";
import { useContext, useState } from "react";
import { CustomButton } from "../../components/CustomButton";
import { ErrorMessage } from "../../components/ErrorMessage";
import { SelectInput } from "../../components/SelectInput";
import { SizedBox } from "../../components/SizedBox";
import { SuccessMessage } from "../../components/SuccessMessage";
import { TextInput } from "../../components/TextInput";
import AppContext from "../../context/app_context";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const BankInformation = () => {
    const { user } = useContext(AppContext);
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState("");
    const [updating, setUpdating] = useState(false);
    const [banks, setBanks] = useState([]);
    const [loading, setLoading] = useState(true);

    const [bankDetails, setBankDetails] = useState({
        bank: "",
        number: "",
    });

    const handleGoBack = () => {
        navigate('/account');
    };

    const getData = async () => {
        try {
            setLoading(true);
            const res = await axios.get(
                `${process.env.REACT_APP_API_URL}/profile/kyc/bank-details`,
                {
                    headers: {
                        Authorization: `Bearer ${user?.access_token}`,
                    },
                }
            );
            let data = {
                bank: "",
                number: "",
            };
            for (let i = 0; i < res.data.data.length; i++) {
                if (res.data.data[i].slug == "bank-name")
                    data.bank = res.data.data[i].value??'Select bank';
                if (res.data.data[i].slug == "bank-account-number")
                    data.number = res.data.data[i].value;
            }

            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/utils/banks`,
                {
                    headers: {
                        Authorization: `Bearer ${user?.access_token}`,
                    },
                }
            );
            setBanks(response.data.data);

            setBankDetails(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log({ error });
        }
    };

    const updateBankInfo = async (e) => {
        try {
            e.preventDefault();
            if (bankDetails.bank == 'Select bank')
                return setError('Please select a bank')
            setUpdating(true);

            const response = await axios.patch(
                `${process.env.REACT_APP_API_URL}/profile/kyc/bank-details`,
                {
                    data: [
                        {
                            value: bankDetails.bank,
                            slug: "bank-name",
                        },
                        {
                            value: bankDetails.number,
                            slug: "bank-account-number",
                        },
                    ],
                },
                {
                    headers: {
                        Authorization: `Bearer ${user?.access_token}`,
                    },
                }
            );
            setUpdating(false);
            toast.success(response.data.message, {
                onClose: () => {
                    navigate('/account/residential-information')
                },
                theme: "light",
                autoClose: 2000,
            });

        } catch (error) {
            setUpdating(false);
            console.log({ error });
            if (error.response) {
                return setError(error.response.data.message);
            }
            return setError(error.msessage);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    if (loading)
        return (
            <div className={styles.loading_body}>
                <svg
                    width="48"
                    height="48"
                    viewBox="-2 -2 48 48"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="#DE183D"
                >
                    <g fill="none" fill-rule="evenodd">
                        <g transform="translate(1 1)" stroke-width="5">
                            <circle
                                stroke-opacity=".5"
                                stroke="#fff"
                                cx="18"
                                cy="18"
                                r="18"
                            />
                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                <animateTransform
                                    attributeName="transform"
                                    type="rotate"
                                    from="0 18 18"
                                    to="360 18 18"
                                    dur="1s"
                                    repeatCount="indefinite"
                                />
                            </path>
                        </g>
                    </g>
                </svg>
            </div>
        );
    return (
        <form onSubmit={updateBankInfo} className={styles.profile_body}>
            {error && <ErrorMessage message={error} close={() => setError(false)} />}
            {success && (
                <SuccessMessage message={success} close={() => setSuccess(false)} />
            )}

            <div>
                <div className={styles.title_bar}>
                    <div className={styles.title_bar_wrap}>
                        <button
                            type='button' 
                            className={styles.title_bar_button}
                            onClick={handleGoBack}
                            aria-label="Go Back"
                        >
                            <svg
                                width="10"
                                height="16"
                                viewBox="0 0 10 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M8 14L2 8L8 2"
                                    stroke="#666D80"
                                    stroke-width="2.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </button>
                        <p className={styles.title_bar_text}>Bank Information</p>
                    </div>
                </div>

                <div className={styles.info_holder}>
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM9.42828 12.107L9.55603 11.5848C9.4899 11.6158 9.3833 11.6513 9.23711 11.6917C9.09052 11.7321 8.95859 11.7527 8.84264 11.7527C8.59567 11.7527 8.42179 11.7122 8.32083 11.6308C8.22062 11.5494 8.17063 11.3964 8.17063 11.1722C8.17063 11.0834 8.18571 10.9509 8.21726 10.7776C8.24782 10.6032 8.28298 10.4481 8.32206 10.3125L8.79897 8.62411C8.84567 8.46916 8.87771 8.2988 8.89492 8.11288C8.91254 7.92736 8.92073 7.79756 8.92073 7.7239C8.92073 7.36786 8.79593 7.07893 8.54626 6.85605C8.29658 6.63333 7.94103 6.52197 7.48027 6.52197C7.22388 6.52197 6.95281 6.56753 6.66593 6.65857C6.37905 6.74936 6.07914 6.85875 5.76539 6.9865L5.63731 7.50913C5.73081 7.47455 5.84217 7.43734 5.97246 7.39883C6.10217 7.36048 6.22943 7.34065 6.35324 7.34065C6.60603 7.34065 6.77631 7.38375 6.86554 7.46865C6.95478 7.55378 6.9996 7.70521 6.9996 7.9217C6.9996 8.04142 6.98542 8.17441 6.95601 8.31896C6.927 8.46441 6.89078 8.61821 6.84817 8.78054L6.36922 10.4758C6.32661 10.6539 6.29547 10.8133 6.27589 10.9548C6.25647 11.0961 6.24713 11.2349 6.24713 11.3697C6.24713 11.7182 6.37586 12.0054 6.63324 12.232C6.89062 12.4578 7.25149 12.5714 7.71545 12.5714C8.01757 12.5714 8.28273 12.5319 8.51094 12.4525C8.7389 12.3734 9.04504 12.2582 9.42828 12.107ZM9.34331 5.24826C9.56611 5.04168 9.67698 4.79045 9.67698 4.49628C9.67698 4.20276 9.56627 3.95103 9.34331 3.74184C9.12108 3.53321 8.85321 3.42857 8.54003 3.42857C8.22586 3.42857 7.95693 3.53297 7.73257 3.74184C7.50821 3.95103 7.39579 4.20268 7.39579 4.49628C7.39579 4.79045 7.50821 5.0416 7.73257 5.24826C7.95734 5.45557 8.22578 5.55931 8.54003 5.55931C8.85329 5.55931 9.12108 5.45557 9.34331 5.24826Z"
                            fill="#325280"
                        />
                    </svg>
                    <span>
                        All commission remittances from your wallet will be made to this
                        account. Contact support for future changes.
                    </span>
                </div>
                <div className={styles.text_holder}>
                    <div className={styles.text_container}>
                        <SelectInput
                            value={bankDetails.bank}
                            options={['Select bank', ...banks.map((bank) => bank.name.toString())]}
                            label="Choose Bank"
                            handleInputChange={({ target }) =>
                                setBankDetails({ ...bankDetails, bank: target.value })
                            }
                        />
                    </div>
                    <div className={styles.text_container}>
                        <TextInput
                            value={bankDetails.number}
                            label="Bank Account Number"
                            handleInputChange={({ target }) =>
                                setBankDetails({ ...bankDetails, number: target.value })
                            }
                        />
                    </div>
                </div>
                <SizedBox height={10} />
                {/* <div className={styles.birth_holder}>
                <TextInput label='Account Name' />
                <span className={styles.bvn_format}>This will be auto-populated from the previous information.</span>
            </div> */}
            </div>
            <div className={styles.footer}>
                <CustomButton loading={updating} title="Submit" />
            </div>
        </form>
    );
};

export default BankInformation;