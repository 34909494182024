import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";



const AppContext = createContext({});

export const AppProvider = ({ children }) => {

    const currentUser = localStorage.getItem('@mflex_user')

    const [user, setUser] = useState(JSON.parse(currentUser))

    const saveUser = (user) => {
        setUser(user);
        localStorage.setItem('@mflex_user', JSON.stringify(user))
    }

    const logout = () => {
        setUser(null);
        localStorage.removeItem('@mflex_user');
    }

    return <AppContext.Provider value={{ user, setUser: saveUser, logout }}>
        {children}
    </AppContext.Provider>
}

export default AppContext;