
import Notification from '../../components/Notifications/Notifications';
import styles from './styles.module.css';

const Notifications = () => {

    const notifications = [
        {
            title: 'Request Approved',
            time: '20 mins ago',
            description: 'Your request for 200 CC Motor Bike (EV) has been approved'
        },
        {
            title: 'Request Approved',
            time: '20 mins ago',
            description: 'Your request for 200 CC Motor Bike (EV) has been approved'
        },
        {
            title: 'Request Approved',
            time: '20 mins ago',
            description: 'Your request for 200 CC Motor Bike (EV) has been approved'
        },
        {
            title: 'Request Approved',
            time: '20 mins ago',
            description: 'Your request for 200 CC Motor Bike (EV) has been approved'
        },
    ];
    return <div>
        <div className={styles.body}>
            <div className={styles.white_card}>
                <div className={styles.card_nav}>
                    <span className={styles.trans_card_title}>Notifications</span>
                </div>
                <div className={styles.transaction_card_holder}>
                    {notifications.map((notification, index) => <>
                        <Notification data={notification} />
                        {index !== (notifications.length - 1) && <div className={styles.notifications_row_divider} />}
                    </>)}
                </div>

            </div>

        </div>

    </div>
};

export default Notifications;