import { SizedBox } from "../../components/SizedBox";
import PinInput from 'react-pin-input';
import styles from './styles.module.css';
import { CustomButton } from "../../components/CustomButton";
import { useContext, useEffect, useState } from "react";
import RegistrationContext from "../../context/auth_context";
import axios from "axios";
import { ErrorMessage } from "../../components/ErrorMessage";
import { useNavigate } from "react-router-dom";
import logo from '../../assets/images/logo.png'

const PasswordOTPVerification = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [time, setTime] = useState('03:00');
    function countdown(minutes) {
        let seconds = 60;
        var mins = minutes
        function tick() {
            var current_minutes = mins - 1
            seconds--;
            setTime("0" + current_minutes.toString() + ":" + (seconds < 10 ? "0" : "") + String(seconds));
            if (seconds > 0) {
                setTimeout(tick, 1000);
            } else {
                if (mins > 1) {
                    countdown(mins - 1);
                }
            }
        }
        tick();
    }

    const {
        data,
        updateData,
    } = useContext(RegistrationContext);
    const { first_name, last_name, phone, email, password, referal_code, otp } = data;

    async function handleSubmit(e) {
        setLoading(true)
        e.preventDefault();
        try {
            if (!otp) {
                setLoading(false)
                return setError('Please provide a valid otp');

            }
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/otp/validate`, {
                phone_number: phone,
                otp,
        
            });
            setLoading(false);
            navigate('/create-password');
        } catch (error) {
            console.log({ error })
            setLoading(false);
            if (error.response) {
                setError(error.response.data.message)
            } else {
                setError(error.message);
            }
        }
    }



    useEffect(() => {
        countdown(3)
    }, [])
    return <div className={styles.body}>
        {error && <ErrorMessage message={error} close={() => setError(false)} />}
        <div className={styles.header}>
            <div className={styles.logo_holder}>
                <img style={{ height: 100, width: 150 }} src={logo} />

                {/* <span className={styles.logo_banner}>By Messenger</span> */}
            </div>
        </div>
        <div className={styles.success_container}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 11H7.83L12.71 6.11997C13.1 5.72997 13.1 5.08997 12.71 4.69997C12.32 4.30997 11.69 4.30997 11.3 4.69997L4.71 11.29C4.32 11.68 4.32 12.31 4.71 12.7L11.3 19.29C11.69 19.68 12.32 19.68 12.71 19.29C13.1 18.9 13.1 18.27 12.71 17.88L7.83 13H19C19.55 13 20 12.55 20 12C20 11.45 19.55 11 19 11Z" fill="#595959" />
            </svg>
            <p className={styles.title}>Enter the code</p>
            <p className={styles.warn_text}>Enter the OTP code sent to your phone number {phone.substring(0, 4)}******{phone.substring(9, 11)} </p>
            <SizedBox height={20} />
            <PinInput
                length={6}
                initialValue={otp}
                onChange={(value, index) =>   updateData('otp', value)}
                type="numeric"
                inputMode="number"
                style={{ width: '100%', display: "flex", justifyContent: 'space-between' }}
                inputStyle={{ borderColor: '#ECEFF3', background: '#F6F8FA', borderRadius: '10px', width: '16%' }}
                inputFocusStyle={{ borderColor: '#0D0D12' }}
                onComplete={(value, index) => { }}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
            <SizedBox height={20} />
            <div className={styles.counter_container}>

                <div className={styles.counter_holder}>
                    <span> Didn’t get a code? <span className={styles.counter_text}>{time != '00:00' ? time : 'Resend'}</span></span>
                </div>
            </div>
            <SizedBox height={20} />
            <CustomButton loading={loading} onClick={handleSubmit} title='Verify phone number' />
            <SizedBox height={20} />
            <p className={styles.warn_text}><a className={styles.red_warn} href='/'> Go back to Sign in</a></p>
        </div>
    </div>
};

export default PasswordOTPVerification;