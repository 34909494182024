import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../components/CustomButton";
import { ErrorMessage } from "../../components/ErrorMessage";
import { SelectInput } from "../../components/SelectInput";
import { SuccessMessage } from "../../components/SuccessMessage";
import { TextInput } from "../../components/TextInput";
import AppContext from "../../context/app_context";
import { AccountContext } from "./AccountLayout";
import styles from "./styles.module.css";
import { toast } from "react-toastify";

const Profile = () => {
  const profilePictureInput = useRef(null);
  const navigate = useNavigate();
  const { profile, setProfile, fetching } = useContext(AccountContext);

  const [profileUpdate, setProfileUpdate] = useState(profile);
  const { user } = useContext(AppContext);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState("");

  const selectProfilePicture = async (file) => {
    try {
      if (file.type.startsWith("image/")) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("name", "image");
        formData.append("description", "profile image");
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/media/image`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${user?.access_token}`,
            },
          }
        );
        setProfileUpdate({ ...profileUpdate, picture: response.data.data });
      } else {
        console.log("wrong format");
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const onClickIcon = () => {
    profilePictureInput?.current?.click();
  };

  const updateProfile = async (e) => {
    try {
      e.preventDefault();
      setUpdating(true);

      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/m-flex/profile/update`,
        {
          first_name: profileUpdate.first_name,
          last_name: profileUpdate.last_name,
          profile_picture_id: profileUpdate?.picture?.id ?? null,
          email: profileUpdate.email,
          dob:profileUpdate.dob,
          gender: profileUpdate.gender,
          marital_status: profileUpdate.marital_status,
          education_level: profileUpdate.education_level,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );


      setProfile({ ...profile, ...response.data.data });
      toast.success(response.data.message, {
        onClose: () => {
          navigate('/account/documents')
        },
        theme: "light",
        autoClose: 2000,
      });

      setUpdating(false);
    } catch (error) {
      setUpdating(false);
      if (error.response) {
        return setError(error.response.data.message);
      }
      return setError(error.msessage);
    }
  };

  useEffect(() => {
    setProfileUpdate(profile);
  }, [profile]);

  useEffect(() => console.log(profile));

  if (fetching)
    return (
      <div className={styles.loading_body}>
        <svg
          width="48"
          height="48"
          viewBox="-2 -2 48 48"
          xmlns="http://www.w3.org/2000/svg"
          stroke="#DE183D"
        >
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="5">
              <circle
                stroke-opacity=".5"
                stroke="#fff"
                cx="18"
                cy="18"
                r="18"
              />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      </div>
    );
  // <Loader />
  return (
    <form className={styles.profile_body} onSubmit={updateProfile}>
      {error && <ErrorMessage message={error} close={() => setError(false)} />}
      {success && (
        <SuccessMessage message={success} close={() => setSuccess(false)} />
      )}
      <div>
        <div className={styles.title_bar}>
          <div className={styles.title_bar_wrap}>
            <button
            type="button"
              className={styles.title_bar_button}
              onClick={handleGoBack}
              aria-label="Go Back"
            >
              <svg
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 14L2 8L8 2"
                  stroke="#666D80"
                  stroke-width="2.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <p className={styles.title_bar_text}>Profile</p>
          </div>
        </div>
        <div className={styles.picture_holder}>
          <img
            className={styles.profile_picture}
            src={profileUpdate?.picture?.url ?? ""}
          />
          <input
            onChange={(e) => {
              console.log("start");
              selectProfilePicture(profilePictureInput?.current?.files[0]);
            }}
            ref={profilePictureInput}
            className={styles.profilePictureInput}
            type={"file"}
            accept="image/png, image/jpeg, image/jpg"
          />
          <button
            type="button"
            onClick={onClickIcon}
            className={styles.camera_holder}
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_7402_280028)">
                <path
                  d="M1.66675 5.13517C1.66675 4.1404 2.47317 3.33398 3.46793 3.33398C3.98479 3.33398 4.44366 3.00325 4.6071 2.51292L4.66675 2.33398C4.69488 2.2496 4.70894 2.20741 4.72398 2.16999C4.91611 1.69207 5.36634 1.36756 5.88049 1.33642C5.92076 1.33398 5.96523 1.33398 6.05417 1.33398H10.6127C10.7016 1.33398 10.7461 1.33398 10.7863 1.33642C11.3005 1.36756 11.7507 1.69207 11.9428 2.16999C11.9579 2.20741 11.972 2.2496 12.0001 2.33398L12.0597 2.51292C12.2232 3.00325 12.682 3.33398 13.1989 3.33398C14.1937 3.33398 15.0001 4.1404 15.0001 5.13517V10.8007C15.0001 11.9208 15.0001 12.4808 14.7821 12.9086C14.5903 13.285 14.2844 13.5909 13.9081 13.7827C13.4802 14.0007 12.9202 14.0007 11.8001 14.0007H4.86675C3.74664 14.0007 3.18659 14.0007 2.75877 13.7827C2.38244 13.5909 2.07648 13.285 1.88474 12.9086C1.66675 12.4808 1.66675 11.9208 1.66675 10.8007V5.13517Z"
                  stroke="#344054"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.33341 11.0007C9.99027 11.0007 11.3334 9.65751 11.3334 8.00065C11.3334 6.3438 9.99027 5.00065 8.33341 5.00065C6.67656 5.00065 5.33341 6.3438 5.33341 8.00065C5.33341 9.65751 6.67656 11.0007 8.33341 11.0007Z"
                  stroke="#344054"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_7402_280028">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0.333374)"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
        <div className={styles.text_holder}>
          <div className={styles.text_container}>
            <TextInput value={profileUpdate?.first_name} label="First Name" />
          </div>
          <div className={styles.text_container}>
            <TextInput value={profileUpdate?.last_name} label="Last Name" />
          </div>
        </div>
        <div className={styles.text_holder}>
          <div className={styles.text_container}>
            <TextInput
              value={profileUpdate?.phone_number}
              label="Phone Number"
            />
          </div>
          <div className={styles.text_container}>
            <TextInput
              value={profileUpdate?.phone_number}
              label="Alternate Phone Number"
            />
          </div>
        </div>
        <div className={styles.text_holder}>
          <div className={styles.text_container}>
            <TextInput value={profileUpdate?.email} label="Email Address" />
          </div>
          <div className={styles.text_container}>
            <TextInput
              value={profileUpdate?.dob}
              type="date"
              handleInputChange={({ target }) =>
                setProfileUpdate({ ...profileUpdate, dob: target.value })
              }
              label="Date of Birth"
            />
          </div>
        </div>
        <div className={styles.text_holder}>
          <div className={styles.text_container}>
            <SelectInput
              value={profileUpdate?.gender}
              options={["Select gender", "Male", "Female"]}
              label="Gender"
              handleInputChange={({ target }) =>
                setProfileUpdate({ ...profileUpdate, gender: target.value })
              }
            />
          </div>
          <div className={styles.text_container}>
            <SelectInput
              value={profileUpdate?.marital_status}
              options={[
                "Select marital status",
                "Single",
                "Married",
                "Divorced",
              ]}
              label="Marital Status"
              handleInputChange={({ target }) =>
                setProfileUpdate({
                  ...profileUpdate,
                  marital_status: target.value,
                })
              }
            />
          </div>
        </div>
        <div className={styles.text_holder}>
          <div className={styles.text_container}>
            <SelectInput
              value={profileUpdate?.education_level}
              options={[
                "Select level of Education",
                "Secondary School Cert/Diploma",
                "Undergraduate (BSc, HND and equivalent)",
                "Postgraduate (MSc or higher)",
              ]}
              label="Education Level"
              handleInputChange={({ target }) =>
                setProfile({ ...profileUpdate, education_level: target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <CustomButton loading={updating} title="Save Changes" />
      </div>
    </form>
  );
};

export default Profile;