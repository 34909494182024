import { Icon } from "./icon";
import styles from './styles.module.css';

export const ErrorMessage = ({ title, message, close }) => {
    return <div className={styles.error_message}>
        <Icon />
        <div className={styles.error_text_holder}>
            <div>
                <p>{message}</p>
            </div>
            <button  className={styles.close_btn} onClick={() => close()}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5 1.5L1.5 10.5M1.5 1.5L10.5 10.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
        </div>
    </div>
}