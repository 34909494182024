import axios from "axios";
import React, { useContext } from "react"
import { useEffect } from "react";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AppContext from "../../context/app_context";
import { NavBar } from "../navBar/NavBar"
import TopMenu from "../topMenu/TopMenu";
import styles from './styles.module.css';

export const DashboardLayout = () => {
    const { setUser, logout } = useContext(AppContext)
    // const [logout, setLogout] = useState(false);

    // useEffect(() => {
    //     if (logout) {
    //         navigate('/');
    //         setUser(null);
    //         localStorage.removeItem('@mflex_user');
    //         setLogout(false);
    //     }
    // }, [logout]);

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response?.status == 401) {
            logout();
        }
        return Promise.reject(error);
    });
    return <div className={styles.container}>
        <TopMenu />
        <div className={styles.card}>
            <Outlet />
        </div>
        <div className={styles.mobile_card_nav} />

    </div>
}