import { CustomButton } from "../../components/CustomButton";
import { SizedBox } from "../../components/SizedBox";
import styles from './styles.module.css';
import logo from '../../assets/images/logo.png'

const SuccessfulRegisteration = () => {

    return <div className={styles.body}>
          <div className={styles.header}>
          <div className={styles.logo_holder}>
                <img style={{ height: 100, width: 150 }} src={logo} />

            </div>
        </div>
        <div className={styles.container}>
           <div className={styles.successIConHolder}>
           <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_383_23021)">
                    <path d="M36 0C16.1184 0 0 16.1128 0 36C0 55.8872 16.1184 72 36 72C55.8816 72 72 55.8773 72 36C72 16.1227 55.8816 0 36 0Z" fill="#67D063" />
                    <path d="M53.4746 31.1124L35.0968 50.3346C34.596 50.8579 33.9943 51.2751 33.3277 51.5613C32.6611 51.8475 31.9434 51.9967 31.2175 52H31.1937C30.4718 52.0002 29.7573 51.8559 29.0926 51.5758C28.4278 51.2956 27.8264 50.8853 27.3239 50.3691L17.5719 40.3649C17.068 39.8611 16.6696 39.2627 16.3998 38.6044C16.13 37.946 15.9942 37.2409 16.0002 36.5299C16.0062 35.8188 16.154 35.116 16.4349 34.4623C16.7158 33.8086 17.1242 33.217 17.6366 32.7218C18.1489 32.2265 18.7549 31.8376 19.4193 31.5775C20.0838 31.3174 20.7935 31.1913 21.5073 31.2066C22.221 31.2219 22.9247 31.3783 23.5773 31.6666C24.2299 31.9548 24.8185 32.3694 25.309 32.8861L31.1554 38.8839L45.6658 23.7074C46.1519 23.1883 46.7365 22.7703 47.3858 22.4776C48.0351 22.185 48.7362 22.0234 49.4486 22.0024C50.161 21.9813 50.8705 22.1011 51.5361 22.3549C52.2017 22.6086 52.8101 22.9913 53.3263 23.4808C53.8424 23.9703 54.256 24.5568 54.5432 25.2065C54.8303 25.8562 54.9852 26.5561 54.999 27.2658C55.0128 27.9755 54.8852 28.6809 54.6235 29.3411C54.3618 30.0014 53.9713 30.6034 53.4746 31.1124Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_383_23021">
                        <rect width="72" height="72" fill="white" />
                    </clipPath>
                </defs>
            </svg>
           </div>
            <p className={styles.title}>Registration successful</p>
            <p className={styles.warn_text}>Welcome to M-Flex, your one stop app to keep you going as a rider. We’re redirecting you to the platform now.</p>
            <SizedBox height={20} />
            <CustomButton eventType={'button'}  title='Done'/>
            <SizedBox height={20} />
        </div>
    </div>
};

export default SuccessfulRegisteration;