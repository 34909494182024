import axios from "axios";
import { useEffect } from "react";
import { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CustomButton } from "../../components/CustomButton";
import { ErrorMessage } from "../../components/ErrorMessage";
import { SuccessMessage } from "../../components/SuccessMessage";
import { TextInput } from "../../components/TextInput";
import AppContext from "../../context/app_context";
import styles from "./styles.module.css";
import { toast } from "react-toastify";

const Guarantor = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const index = searchParams.get("index");
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const { user } = useContext(AppContext);
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState("");
    const [guarantor, setGuarantor] = useState({
        first_name: "",
        last_name: "",
        email_address: "",
        phone: "",
    });

    const getData = async () => {
        try {
            if (index) {
                setLoading(true);
                const res = await axios.get(
                    `${process.env.REACT_APP_API_URL}/profile/kyc/guarantor${index == 2 ? "-2" : ""
                    }`,
                    {
                        headers: {
                            Authorization: `Bearer ${user?.access_token}`,
                        },
                    }
                );
                let data = {
                    first_name: "",
                    last_name: "",
                    email_address: "",
                    phone: "",
                };
                for (let i = 0; i < res.data.data.length; i++) {
                    if (res.data.data[i].slug == "first-name")
                        data.first_name = res.data.data[i].value;
                    if (res.data.data[i].slug == "last-name")
                        data.last_name = res.data.data[i].value;
                    if (res.data.data[i].slug == "email-address")
                        data.email_address = res.data.data[i].value;
                    if (res.data.data[i].slug == "phone-number")
                        data.phone = res.data.data[i].value;
                }
                setGuarantor(data);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const updateData = async (e) => {
        try {
            setUpdating(true);
            e.preventDefault();
            const response = await axios.patch(
                `${process.env.REACT_APP_API_URL}/profile/kyc/guarantor${index == 2 ? "-2" : ""
                }`,
                {
                    data: [
                        {
                            value: guarantor.first_name,
                            slug: "first-name",
                        },
                        {
                            value: guarantor.last_name,
                            slug: "last-name",
                        },

                        {
                            value: guarantor.email_address,
                            slug: "email-address",
                        },
                        {
                            value: guarantor.phone,
                            slug: "phone-number",
                        },
                    ],
                },
                {
                    headers: {
                        Authorization: `Bearer ${user?.access_token}`,
                    },
                }
            );

            setUpdating(false);
            if(index==2){
                toast.success(response.data.message, {
                onClose: () => {
                  navigate('/account/nok')
                },
                theme: "light",
                autoClose: 2000,
              });
            }else{
                toast.success(response.data.message, {
                    onClose: () => {
                      navigate('/account/guarantors/update?index=2')
                    },
                    theme: "light",
                    autoClose: 2000,
                  });
              }
           
        } catch (error) {
            setUpdating(false);

            if (error.response) {
                return setError(error.response.data.message);
            }
            return setError(error.msessage);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    if (loading)
        return (
            <div className={styles.loading_body}>
                <svg
                    width="48"
                    height="48"
                    viewBox="-2 -2 48 48"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="#DE183D"
                >
                    <g fill="none" fill-rule="evenodd">
                        <g transform="translate(1 1)" stroke-width="5">
                            <circle
                                stroke-opacity=".5"
                                stroke="#fff"
                                cx="18"
                                cy="18"
                                r="18"
                            />
                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                <animateTransform
                                    attributeName="transform"
                                    type="rotate"
                                    from="0 18 18"
                                    to="360 18 18"
                                    dur="1s"
                                    repeatCount="indefinite"
                                />
                            </path>
                        </g>
                    </g>
                </svg>
            </div>
        );
    return (
        <form className={styles.profile_body} onSubmit={updateData}>
            {error && <ErrorMessage message={error} close={() => setError(false)} />}
            {success && (
                <SuccessMessage message={success} close={() => setSuccess(false)} />
            )}

            <div>
                <div className={styles.title_bar}>
                    <button type='button'  onClick={() => navigate('/account/guarantors')} className={styles.back_btn}>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M15 18L9 12L15 6"
                                stroke="#666D80"
                                stroke-width="2.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </button>
                    <p className={styles.title_bar_text}>Guarantor</p>
                </div>

                <div className={styles.text_holder}>
                    <div className={styles.text_container}>
                        <TextInput
                            value={guarantor.first_name}
                            handleInputChange={({ target }) =>
                                setGuarantor({ ...guarantor, first_name: target.value })
                            }
                            label="First Name"
                        />
                    </div>
                    <div className={styles.text_container}>
                        <TextInput
                            value={guarantor.last_name}
                            handleInputChange={({ target }) =>
                                setGuarantor({ ...guarantor, last_name: target.value })
                            }
                            label="Last Name"
                        />
                    </div>
                </div>
                <div className={styles.text_holder}>
                    <div className={styles.text_container}>
                        <TextInput
                            value={guarantor.phone}
                            handleInputChange={({ target }) =>
                                setGuarantor({ ...guarantor, phone: target.value })
                            }
                            label="Phone Number"
                        />
                    </div>
                    <div className={styles.text_container}>
                        <TextInput
                            value={guarantor.email_address}
                            handleInputChange={({ target }) =>
                                setGuarantor({ ...guarantor, email_address: target.value })
                            }
                            label="Email Address"
                        />
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <CustomButton loading={updating} title="Submit" />
            </div>
        </form>
    );
};

export default Guarantor;