import styles from './styles.module.css';
import icon from './icon.svg';
import { Link } from 'react-router-dom';


export const CustomButton = ({
    link = '/',
    title,
    eventType,
    onClick,
    loading,
    type = "primary",
    size = "normal",
    ...props
}) => {

    if (eventType === 'button')
        return <Link to={link} >
            <button className={`${styles[`${size}_${type}_button`]} inner_button`}>
                <p >
                    {title}
                </p>
            </button>
        </Link>


    return <button onClick={onClick} {...props} type={eventType} className={styles[`${size}_${type}_button`]}>
        {!loading && <p className={styles.button_text}> {title}</p>}
        {loading && <img src={icon} className={styles.loading_btn} alt='loader' height={20} width={20} />}
    </button>
}