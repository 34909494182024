import { createContext, useState } from "react";



const RegistrationContext = createContext({});

export const RegistrationProvider = ({ children }) => {

    const [userData, setUserData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        otp: '',
        referal_code: '',
        password: '',
        confirm_password:'',
    })

    const updateData = (name, value) => setUserData({ ...userData, [name]: value });

    return <RegistrationContext.Provider value={{ data: userData, updateData }}>
        {children}
    </RegistrationContext.Provider>
}

export default RegistrationContext;