import { money_format } from '../../utils/money_format';
import styles from './styles.module.css';

const TransactionCard = ({transaction}) =>{
    const date = new Date(transaction.transaction_date)
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    return <div className={styles.transaction_row}>
        <div className={styles.transaction_row_head}>
            <span className={styles.transaction_row_title}>
                {transaction.narration}
            </span>
            <span className={styles.transaction_row_label}>
                {date.toLocaleDateString(undefined, options)} @  {date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}
            </span>

        </div>
        <p className={transaction.entry_type=='debit'?styles.transaction_row_dedit:styles.transaction_row_credit}>{transaction.entry_type=='debit'?'-':'+'} {money_format(transaction.amount)}</p>
    </div>
};

export default TransactionCard;